import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],


})
export class DatePickerComponent {
  range: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
      this.range = this.fb.group({
          start: [null], // Add your initial values or null
          end: [null]
      });
  }
}

<div class="dialog-container">
    <div class="header-div">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <span class="title">{{data.modalTitle }}</span>
    </div>
    <div class="modal-scroll">
        <ng-content></ng-content>
    </div>
</div>
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
import SolidGauge from 'highcharts/modules/solid-gauge';
SolidGauge(Highcharts);

@Component({
  selector: 'solid-gauge',
  templateUrl: './solid-gauge.component.html',
  styleUrls: ['./solid-gauge.component.scss']
})
export class SolidGaugeComponent implements OnInit, OnChanges {
  @Input() seriesData;
  @Input() chartTitle;
  @Input() chartHeight;
  @Input() chartWidth;
  updateFlag = false;
  chartOptions: Highcharts.Options;
  Highcharts: typeof Highcharts = Highcharts;
  @Input() colorStops: [number, Highcharts.ColorType];

  constructor() { }
  ngOnInit(): void {
    this.initializeChart();
  }

  initializeChart() {
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        backgroundColor: 'none',
        height: this.chartHeight,
        width: this.chartWidth,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0]
      },
      title: {
        text: this.chartTitle,
        style: {
          display: 'none',
          fontSize: '14px',
          color: '#D7D7DC',
          fontStyle: 'normal',
          fontweight: '300',
          textOutline: 'none'
        }
      },
      pane: {
        center: ['50%', '60%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: [{
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '61%',
          outerRadius: '98%',
          shape: 'arc'
        }]
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        enabled: true,
      },
      yAxis: {
        title: {
          text: this.chartTitle,
          y: 60,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        stops: [this.colorStops],
        labels: {
          enabled: false,
          y: 15,
          x: 0,
          formatter: function () {
            return this.value + '%';
          },
          style: {
            color: '#fff',
            fontSize: '12px',
          }
        }
      },
      plotOptions: {
        solidgauge: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            style: {
              color: '#F9F9FA',
              fontSize: '8px',
              textOutline: 'none',
              textShadow: 'none',
            }
          }
        }
      },
      series: [
        {
          type: 'solidgauge',
          name: this.chartTitle,
          data: [this.seriesData],
          dataLabels: {
            format: '<div style="text-align:center; border:0">' +
              '<span style="font-size:12px; border:none">{y:.1f}%</span><br/>' +
              '</div>',
            style: {
              color: '#fff',
              fontSize: '12px',
              border: '0'
            },
          },
          tooltip: {
            valueSuffix: '%',
            format: "{point.y:,.0f}%",
          }
        },
      ],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.seriesData) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }
}

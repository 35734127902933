import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import Highcharts from 'highcharts';
import HC_sunburst from "highcharts/modules/sunburst";
import Drilldown from "highcharts/modules/drilldown";

HC_sunburst(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SunburstComponent implements OnInit {
  @Input() chartHeight;
  @Input() sunburstData: any[];
  @Input() sunburstSeries: any[];
  @Input() sunburstHeight: any;


  highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  chart: Highcharts.Chart;

  constructor() { }

  getDataSource = () => this.sunburstData;

  ngOnInit() {
    this.changeData1();
  }

  loadChart() {
    this.chartOptions = {
      chart: {
        height: this.chartHeight,
        backgroundColor: 'none',
        events: {
          load: (e) => {
            this.chart = e.target;
            this.chart.reflow();
          }
        }
      },
      plotOptions: {
        sunburst: {
          borderColor: '#414257',
          borderWidth: 0.2,
          dashStyle: 'dash'
        }
      },
      title: {
        text: ""
      },
      navigation: {
        buttonOptions: {
          enabled: false // Disable back button
        }
      },
      series: this.sunburstSeries,

      tooltip: {
        headerFormat: "",
        pointFormat: "<b>{point.name}</b> has <b>{point.value}</b> %"
      },
      credits: { enabled: false }
    } as any;
  }

  doDrillUp() {
    const chart = this.chart;
    if (chart) {
      const series = chart.series[0] as any;
      const mainRootNode = series.tree.children[0].id;

      while (series.rootNode !== mainRootNode) {
        series.drillUp();
      }
    }
  }

  changeData1() {
    this.doDrillUp();
    this.sunburstData = this.getDataSource();
    this.loadChart();
  }
}

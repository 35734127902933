import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-pbi-loader',
  templateUrl: './pbi-loader.component.html',
  styleUrls: ['./pbi-loader.component.scss']
})
export class PbiLoaderComponent implements OnInit {
  embedUrl: any;
  id: any;
  accessToken: any;

  constructor(private apiService: ApiService,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.displayPbi();
  }

  ngOnInit(): void { }

  displayPbi() {
    const menuData = this.utilsService.menuData;
    const currentRoute = this.router.url;
    const dynamicData = this.getreportId(menuData, currentRoute);
    this.apiService.getPBIToken(dynamicData.reportId).subscribe((response) => {
      if (response) {
        this.embedUrl = response.embedUrl;
        this.id = response.id;
        this.accessToken = response.accessToken;
      }
    });
  }

  getreportId(menuData, currentRoute): any {
    let reportId = '';
    for (const menu of menuData) {
      if (menu.routerLink === currentRoute) {
        reportId = menu.reportId;
        break;
      } else {
        if (menu.children) {
          for (const subMenu of menu.children) {
            if (subMenu.routerLink === currentRoute) {
              reportId = subMenu.reportId;
              break;
            } else {
              if (subMenu.children) {
                for (const child of subMenu.children) {
                  if (child.routerLink === currentRoute) {
                    reportId = child.reportId;
                    break;
                  }
                  else {
                    if (child.children) {
                      for (const children of child.children) {
                        if (children.routerLink === currentRoute) {
                          reportId = children.reportId;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return { reportId };
  }
}

<div class="cards-container">
  <div class="row right-card-comp">
    <div class="col-md-4 col-lg-4 col-xl-4 col-cards" *ngFor="let data of summaryCardData">
      <div class="card card-stats mb-4 mb-xl-0 mps-card">
        <div class="card-body">
          <div class="data-image">
            <mat-icon class="task-icon" [ngStyle]="{ 'color': data.color }">{{ data.icon }}</mat-icon>
            <span class="card-title">{{ data.name }}</span>
          </div>
          <div class="score-txt">
            <span class="score" [ngStyle]="{ 'color': data.color }">{{ data.score }}</span>          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import FullScreen from 'highcharts/modules/full-screen';
Exporting(Highcharts);
ExportData(Highcharts);
FullScreen(Highcharts);

@Component({
  selector: 'high-chart',
  templateUrl: './high-chart.component.html',
  styleUrls: ['./high-chart.component.scss']
})
export class HighChartComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() seriesData: any;
  updateFlag = false;
  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;
  @Input() yAxisMin: number;
  @Input() yAxisMax: number;
  @Input() yAxisTickAmount: number;
  @Input() chartHeight: string | number;
  @Input() areasplineOptions: Highcharts.SeriesAreasplineOptions = null;
  @Input() enableLegend: boolean = true;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() stacking: Highcharts.OptionsStackingValue;
  @Input() enabledExporting: boolean = false;

  constructor() { }
  ngOnInit(): void { }

  initializeChart() {
    if (this.seriesData && this.seriesData.length > 0) {
      this.chartOptions = {
        chart: {
          backgroundColor: 'none',
          height: this.chartHeight
        },
        exporting: {
          enabled: this.enabledExporting,
          buttons: {
            contextButton: {
              theme: {
                fill: '#131329',
                stroke: '#FFFFFF',
              },
              //symbolStroke: '#FFFFFF',
              //symbolFill: '#131329',
              menuItems: [
                'viewFullscreen',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG'
              ]
            }
          }
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        credits: { enabled: false },
        xAxis: {
          type: 'category',
          title: {
            text: this.xAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            style: {
              color: '#AFAEBA'
            }
          }
        },
        yAxis: {
          title: {
            text: this.yAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            format: this.yAxisLabelFormat,
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          },
          min: this.yAxisMin,
          max: this.yAxisMax,
          tickAmount: this.yAxisTickAmount,
          gridLineColor: '#414257',
          gridLineDashStyle: 'Dash',
        },
        legend: {
          enabled: this.enableLegend,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          itemStyle: {
            color: '#8F8FAC',
            borderRadius: 5
          },
          itemHoverStyle: {
            color: '#fffff',
            cursor: 'pointer'
          },
          itemCheckboxStyle: {
            color: '#8F8FAC',
            borderRadius: 5
          },
          symbolRadius: 0,
          //itemDistance: 5,
          //width: 450,
          //itemWidth: 140,
        },
        plotOptions:
        {
          area: {
            stacking: "normal",
            lineColor: "#B61FB0",
            lineWidth: 1,
            marker: {
              enabled: false
            },
            color: "#2b193f",
            dataLabels: {
              enabled: true,
              inside: true,
              verticalAlign: 'bottom',
              y: 20,
              formatter: function () {
                return this.y + '%';
              },
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal'
              }
            }
          },
          column: {
            stacking: this.stacking,
          },
          areaspline: this.areasplineOptions || {
            marker: {
              enabled: false // Disable markers for areaspline series
            }
          },
          line: {
            lineWidth: 2,
          },
          columnpyramid: {
            borderWidth: 0
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b><br>' + '{point.y}' + this.yAxisLabelFormat,
              distance: '0',
              style: {
                fontSize: '10px',
                color: '#A7A7DC'
              }
            },
            showInLegend: true
          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: false,
              inside: true,
              format: '{point.y}' + this.yAxisLabelFormat,
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal'
              },
            },
          }
        },
        series: this.seriesData.slice()
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seriesData'] && changes['seriesData'].currentValue) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }

  updateChart() {
    if (this.seriesData && this.seriesData.length > 0) {
      this.chartOptions.series = this.seriesData.slice(); // Update with new data
      this.updateFlag = true; // Indicate that the chart needs to be updated

      // Reset updateFlag after the chart has been updated
      setTimeout(() => {
        this.updateFlag = false; // Reset the flag after update
      }, 0);
    }
  }
}


import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'iframe-loader',
  templateUrl: './iframe-loader.component.html',
  styleUrls: ['./iframe-loader.component.scss']
})
export class IframeLoaderComponent implements OnInit {
  url: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const unsafeUrl = params['url'];
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    });
  }
}

import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as Highcharts from "highcharts";
import HC_more from 'highcharts/highcharts-more';
import AnnotationsModule from 'highcharts/modules/annotations';
HC_more(Highcharts);
AnnotationsModule(Highcharts);

@Component({
  selector: 'app-stacked-bar',
  templateUrl: './stacked-bar.component.html',
  styleUrls: ['./stacked-bar.component.scss']
})
export class StackedBarComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() chartData: any[];
  @Input() xAxisCategories: any[];
  @Input() chartType;
  @Input() yAxis: any[];
  @Input() chartHeight: number;
  @Input() stackType?: any;
  @Input() annotations?: any[]
  @Input() xAxisTitle?: string;
  @Input() legend?: boolean = true;
  @Output() skuPlantSelected = new EventEmitter<string>();
  updateFlag = false;

  constructor() { }

  ngOnInit(): void {
    this.loadStackedBarChart();
  }

  loadStackedBarChart() {
    if (this.chartData && this.chartData.length > 0) {
      this.chartOptions = {
        chart: {
          type: this.chartType,
          backgroundColor: 'none',
          height: this.chartHeight,
        },
        title: {
          text: '',
          style: {
            color: '#A7A7DC',
            fontSize: '1em'
          }
        },
        xAxis: {
          categories: this.xAxisCategories,
          labels: {
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          },
          title: {
            text: this.xAxisTitle,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
        },
        yAxis: this.yAxis,
        credits: { enabled: false },
        exporting: { enabled: false },
        legend: {
          enabled: this.legend,
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
          itemStyle: {
            color: '#8F8FAC',
            borderRadius: 5
          },
          itemHoverStyle: {
            color: '#fffff',
            cursor: 'pointer'
          },
          symbolRadius: 0,
        },
        plotOptions: {
          series: {
            stacking: this.stackType,
            borderColor: 'none',
            point: {
              events: {
                click: this.chartType === 'bar' ? (event) => this.onPointClick(event) : undefined
              }
            }
          },
          area: {
            dataLabels: {
              enabled: false
            },
          },
        },
        annotations: this.annotations,
        series: this.chartData.slice()
      }
    }
  };

  onPointClick(event: any) {
    if (this.chartType === 'bar') {
      const skuPlant = event.point.category; // adapt based on your data structure
      this.skuPlantSelected.emit(skuPlant);
      console.log(event)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData || changes.chartType) {
      // setTimeout(() => {
      //   this.loadStackedBarChart();
      // }, 5000);
      this.loadStackedBarChart();
      // this.chartOptions.annotations = this.annotations
      this.updateFlag = true;
    }
  }
}

import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SKUDetailService } from 'src/app/services/sku/sku-datail.service';

@Component({
  selector: 'app-table-reusable',
  templateUrl: './table-reusable.component.html',
  styleUrls: ['./table-reusable.component.scss']
})
export class TableReusableComponent {

  @Input() id!: string;

  displayedColumns = ['Values', '01-10-2023',
  '01-11-2023', '01-12-2023', '01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024',
  '01-06-2024', '01-07-2024', '01-08-2024', '01-09-2024'];

  dataSource = new MatTableDataSource<any>();

  ngOnInit(): void {
    this.dataSource.data = this.skuDetails.data.filter(temp => temp.EmItemNum === this.id);
    this.dataSource.data.forEach((item) => {
      if(item.Values === 'Forecast Accuracy') {
        for(var key in item) {
          if(!['EmItemNum', 'Plant', 'PartCriticality', 'Values'].includes(key) && Number.isInteger(+item[key]))
          item[key] = item[key] ? `${item[key]}%` : ''
        }
      }
    })
  }

  constructor(private skuDetails: SKUDetailService) {
    
    }

}

import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

export interface DialogData {
  title: string;
  template: any;
}

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  public vcRef: ViewContainerRef;
  public componentRef: ComponentRef<any>;
  @ViewChild('target', { read: ViewContainerRef }) container!: ViewContainerRef;
  prodDowntimeData: any;
  displayProdDowntime: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private resolver: ComponentFactoryResolver
  ) {
    this.prodDowntimeData = this.data.template;
    if (this.prodDowntimeData) {
      this.displayProdDowntime = true
    } else {
      this.displayProdDowntime = false;
    }
  }
  ngAfterViewInit() {
    /* this.container.createEmbeddedView(this.data.template);
    const factory = this.resolver.resolveComponentFactory(this.data.template);
    this.componentRef = this.vcRef.createComponent(factory);
    console.log(this.componentRef); */
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    /* if (this.componentRef) {
      this.componentRef.destroy();
    } */
  }

  openAssetLink(assetName: string) {
    switch (assetName) {
      case "Inventory Optimizer": {
        this.router.navigate(['/home/inventory-optimizer']);
        this.dialogRef.close();
        break;
      }
      case "S&OE": {
        this.router.navigate(['/home/soe/kpi']);
        this.dialogRef.close();
        break;
      }
      case "Nostradamus": {
        this.dialogRef.close();
        break;
      }
      case "Warehouse Analyzer": {
        this.router.navigate(['/home/warehouse-analyzer']);
        this.dialogRef.close();
        break;
      }
      case "Transport Analyzer": {
        this.router.navigate(['/home/transportation-analyzer']);
        this.dialogRef.close();
        break;
      }
      default: {
        break;
      }
    }
  }
}
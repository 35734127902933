<div class="diagram-container">
    <div class="outer-circle" [ngClass]="{'visible': outerCircleVisible}">
        <div class="segment segment1">
            <button mat-icon-button>
                <mat-icon class="segment-icon" [style.color]="color">lock</mat-icon>
            </button>
        </div>
        <div class="segment segment2">
            <button mat-icon-button>
                <mat-icon class="segment-icon" [style.color]="color">visibility</mat-icon>
            </button>
        </div>
        <div class="segment segment3">
            <button mat-icon-button>
                <mat-icon class="segment-icon" [style.color]="color">share</mat-icon>
            </button>
        </div>
    </div>
    <div class="center-circle" [style.background]="color" (click)="toggleOuterCircle()">{{name}}</div>
</div>
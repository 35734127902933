import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SKUDetailService } from 'src/app/services/sku/sku-datail.service';

@Component({
  selector: 'app-data-table-sku',
  templateUrl: './data-table-sku.component.html',
  styleUrls: ['./data-table-sku.component.scss']
})
export class DataTableSkuComponent implements AfterViewInit {
  [x: string]: any;

  displayedColumns = ['EmItemNum', 'Plant', 'PartCriticality', 'Values', '01-10-2023',
    '01-11-2023', '01-12-2023', '01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024',
    '01-06-2024', '01-07-2024', '01-08-2024', '01-09-2024'];
  spanningColumns = ['EmItemNum', 'Plant', 'PartCriticality'];

  // 'Locations', 'Part Criticality', 'Values', '01-10-2023',
  //   '01-11-2023', '01-12-2023', '01-01-2024', '01-02-2024', '01-03-2024', '01-04-2024', '01-05-2024',
  //   '01-06-2024', '01-07-2024', '01-08-2024', '01-09-2024'

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  spans: any[] = [];

  constructor(private skuDetails: SKUDetailService, private router: Router) {


    this.skuDetails.data.forEach((item: any) => {
      if (item.Values === 'Forecast Accuracy') {
        for (var key in item) {
          if (!['EmItemNum', 'Plant', 'PartCriticality', 'Values'].includes(key) && Number.isInteger(+item[key]))
            item[key] = item[key] ? `${item[key]}%` : ''
        }
      }
    });

    this.dataSource.data = this.skuDetails.data;

    this.cacheSpan('EmItemNum', (d: any) => d.EmItemNum);
    this.cacheSpan('Plant', (d: any) => d.Plant);
    this.cacheSpan('PartCriticality', (d: any) => d.PartCriticality);
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /**
 * Evaluated and store an evaluation of the rowspan for each row.
 * The key determines the column it affects, and the accessor determines the
 * value that should be checked for spanning.
 */
  cacheSpan(key: any, accessor: any) {
    for (let i = 0; i < this.dataSource.data.length;) {
      let currentValue = accessor(this.dataSource.data[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.dataSource.data.length; j++) {
        if (currentValue != accessor(this.dataSource.data[j])) {
          break;
        }

        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col: any, index: any) {
    return this.spans[index] && this.spans[index][col];
  }

  navigateToDetails(itemId: string) {
    this.router.navigate(['/home/sku-details', itemId]);
  }

}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-bar-line-chart',
  templateUrl: './bar-line-chart.component.html',
  styleUrls: ['./bar-line-chart.component.scss']
})
export class BarLineChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highchart
  @Input() seriesData;
  updateFlag = false;
  // categories: any = [];
  // @Input() pointWidth;
  @Input() plotLinesData;
  @Input() heightData;
  @Input() secondaryYAxisMin;
  @Input() secondaryYAxisMax;
  @Input() enableLegend = false;
  @Input() yAxisTickAmount = 6;
  @Input() yAxisLabelFormat?: string = '{value}';

  constructor(private router: Router) { }

  chartOptions: Highcharts.Options = {
    chart: {
      // zoomType: 'xy'
      type: 'xy',
      backgroundColor: 'none'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    xAxis: {
      //crosshair: true,
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      // gridLineWidth: .5,
      categories: [],
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      accessibility: {
        description: ''
      },
      labels: {
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      }
    },
    //yAxis: this.yAxisData,
    yAxis: [
      { // Primary yAxis
        tickAmount: this.yAxisTickAmount,
        gridLineColor: '#414257',
        gridLineDashStyle: 'Dash',
        // gridLineWidth: .5,
        labels: {
          format: this.yAxisLabelFormat,
          style: {
            color: '#AFAEBA',
            //fontSize: '1em'
          }
        },
        title: {
          text: '',
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
      }, { // Secondary yAxis
        gridLineColor: '#414257',
        gridLineDashStyle: 'Dash',
        tickAmount: this.yAxisTickAmount,
        // gridLineWidth: .5,
        title: {
          text: '',
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
        labels: {
          format: this.yAxisLabelFormat,
          style: {
            color: "#AFAEBA"
          }
        },
        opposite: true
      }
    ],
    tooltip: {
      shared: true
    },
    plotOptions: {
      series: {
        animation: false,
        shadow: false,
        borderWidth: 0, // Set borderWidth to 0 to remove the column borders
        dataLabels: {
          enabled: false,
          inside: true,
          format: '{point.y}' + this.yAxisLabelFormat,
          style: {
            color: '#f9f9fa',
            fontWeight: 'normal'
          },
        },
      }
    },
    legend: {
      enabled: this.enableLegend,
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      itemStyle: {
        color: '#8F8FAC',
        borderRadius: 5
      },
      itemHoverStyle: {
        color: '#fffff',
        cursor: 'pointer'
      },
      symbolRadius: 0,
    },
    series:
      [{
        // name: 'Precipitation',
        type: 'column',
        data: [],
      }, {
        type: 'spline',
        data: [],
        yAxis: 1,
      }]

  }


  ngOnInit(): void {
    setTimeout(() => {
    }, 2000);
    this.chartOptions.legend = {
      ...this.chartOptions.legend,
      enabled: this.enableLegend,
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const seriesData = changes.seriesData.currentValue;
    // console.log("Here values given  ---", seriesData)
    if (seriesData && seriesData.length > 0) {
      let chartOprtnsData = [];
      let xAxisData = [];
      let xAxisTitle = seriesData[0].xAxisTitle;
      let yAxisTitle = seriesData[0].yAxisTitle;
      let yAxisRightTitle = seriesData[1]?.yAxisTitleRight;
      seriesData.map((el, ind) => {
        let dataArr = [];
        if (el.series && el.series.length) {
          el.series.forEach((dat) => {
            dataArr.push(dat.y);
            xAxisData.push(dat.category)
          });
        }
        if (this.router.url.includes('oee-dashboard')) {
          chartOprtnsData.push({
            name: el.name,
            type: el.type,
            yAxis: ind.type === 'line' ? 1 : 0,
            data: dataArr,
          });
        } else {
          chartOprtnsData.push({
            name: el.name,
            type: ind == 0 ? 'column' : 'spline',
            data: dataArr,
            color: ind == 0 ? seriesData[0].columnColor : seriesData[0].lineColor
          });
        }
      });
      this.chartOptions.series = chartOprtnsData;
      this.chartOptions.chart.height = this.heightData ? this.heightData : 250;
      (<Highcharts.AxisOptions>this.chartOptions.xAxis).title = {
        text: xAxisTitle,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      };
      (<Highcharts.AxisOptions>this.chartOptions.yAxis)[0].labels = {
        format: this.yAxisLabelFormat,
        style: { color: '#AFAEBA' }
      };
      (<Highcharts.AxisOptions>this.chartOptions.yAxis)[0].title = {
        text: yAxisTitle,
        min: this.secondaryYAxisMin,
        max: this.secondaryYAxisMax,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      };
      (<Highcharts.AxisOptions>this.chartOptions.xAxis).categories = xAxisData;
      (<Highcharts.AxisOptions>this.chartOptions.yAxis)[1].min = this.secondaryYAxisMin;
      (<Highcharts.AxisOptions>this.chartOptions.yAxis)[1].max = this.secondaryYAxisMax;
      if (yAxisRightTitle) {
        (<Highcharts.AxisOptions>this.chartOptions.yAxis)[1].labels = {
          format: this.yAxisLabelFormat,
          style: { color: '#AFAEBA' }
        };
        (<Highcharts.AxisOptions>this.chartOptions.yAxis)[1].title = {
          text: yAxisRightTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        };
      }
      // (<Highcharts.AxisOptions>this.chartOptions.yAxis).plotLines[0] = { color: '#afafba', value:85, width: 1, zIndex: 5};
      if (this.plotLinesData) {
        var addPlotLines = [];
        changes.plotLinesData.currentValue.forEach((el) => {
          addPlotLines.push({
            "color": "#afafba",
            "width": "1",
            "value": el.value,
            "zIndex": "5"
          });
          (<Highcharts.YAxisOptions>this.chartOptions.yAxis[1]).plotLines = addPlotLines;
        })
      }
    };
    this.updateFlag = true;
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import topology from '@highcharts/map-collection/countries/us/us-all.topo.json';

@Component({
  selector: 'us-counties-map',
  templateUrl: './us-counties-map.component.html',
  styleUrls: ['./us-counties-map.component.scss']
})
export class UsCountiesMapComponent implements OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'mapChart';
  @Input() seriesData = [];
  @Input() mapHeight = 300;
  updateFlag = false;
  plantList = [];
  mapData = [];

  chartOptions: Highcharts.Options = {
    chart: {
      map: topology,
      height: 300,
      backgroundColor: 'none',
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="color:{point.color};">' + '<br/>',
      pointFormat: '<span style="color:rgba(19, 19, 41, 1);">{point.name}: <b>{point.value}% </span></b><br/></span>',
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: 'right',
      },
    },
    colorAxis: {
      min: 0,
      minColor: '#E6E7E8',
      maxColor: '#005645',
    },
    plotOptions: {
      mappoint: {
        dataLabels: {
          enabled: false
        }
      },
      map: {
        allAreas: true,
        nullColor: '#5E5E81',
        borderColor: '#ffffff',
        borderWidth: 0.1
      }
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          format: '{point.properties.postal-code}',
        },
        allAreas: true,
        data: [],
      } as Highcharts.SeriesMapOptions,
      {
        type: 'mappoint',
        data: this.mapData
      }
    ]
  } as any;

  ngOnChanges(changes: SimpleChanges) {
    const seriesData = changes.seriesData.currentValue;
    if (seriesData && seriesData.length > 0) {
      this.mapData = seriesData.map((el) => {
        return {
          lat: el.latitude,
          lon: el.longitude,
          name: el.plantName,
          color: el.color,
          value: el.value,
          marker: {
            radius: el.radius,
            fillColor: el.fillColor, // Use the fillColor for the circle
            lineColor: el.lineColor, // Use the lineColor for the border
            lineWidth: 1 // Set the border width
          }
        }
      });

      this.chartOptions.series = [
        {
          data: [],
        } as Highcharts.SeriesMapOptions,
        {
          type: 'mappoint',
          marker: {
            radius: 7
          },
          data: this.mapData
        }
      ]
    }
    this.updateFlag = true;
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelDetailService {
  data = [
    {
      Values: 'MAPE',
      'Machine Learning': '12%',
      'Timeseries': '23%',
    },
    {
      Values: 'RMSE',
      'Machine Learning': '253.82',
      'Timeseries': '489.88',
    },
    {
      Values: 'MAD',
      'Machine Learning': '217.17',
      'Timeseries': '500',
    },
    {
      Values: 'Bias',
      'Machine Learning': '48.3',
      'Timeseries': '60.9',
    },
    
  ]
}

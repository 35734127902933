<div class="header">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<hr>
<div>
    <form [formGroup]="form" class="custom-form">
        <mat-form-field appearance="outline">
            <mat-label>Requester Email</mat-label>
            <input matInput formControlName="email" placeholder="Enter your email" autocomplete="off">
            <mat-error *ngIf="form.controls.email.invalid">{{ getErrorMessage('email') }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Requester GPN</mat-label>
            <input matInput formControlName="gpn" placeholder="Enter your GPN" autocomplete="off">
            <mat-error *ngIf="form.controls.gpn.invalid">{{ getErrorMessage('gpn') }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-textarea">
            <mat-label>Business Justification</mat-label>
            <textarea matInput formControlName="businessJustification" placeholder="Enter the business justification"
                maxlength="200" autocomplete="off"></textarea>
            <mat-hint align="end">{{ form.controls.businessJustification.value.length }}/200</mat-hint>
            <mat-error *ngIf="form.controls.businessJustification.invalid">{{ getErrorMessage('businessJustification')
                }}</mat-error>
        </mat-form-field>

    </form>
</div>
<hr>
<div class="footer">
    <button mat-button mat-dialog-close (click)="onCancel()" class="cancel-btn">Close</button>
    <button mat-button mat-dialog-close (click)="onSubmit()" [disabled]="form.invalid"
        class="cancel-btn">Submit</button>
</div>
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class UtilsService {
    private _menuData: any;

    constructor() { }

    get menuData() {
        return this._menuData;
    }

    set menuData(data: any) {
        this._menuData = data;
    }

    getRoleId(): number {
        const role = localStorage.getItem('role');
        switch (role?.toLowerCase()) {
            case 'admin': return 1;
            // case 'vp of operations': return 2;
            // case 'plant operator': return 3;
        }
    }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import variwide from 'highcharts/modules/variwide';
variwide(Highcharts);

@Component({
  selector: 'variwide',
  templateUrl: './variwide-chart.component.html',
  styleUrls: ['./variwide-chart.component.scss']
})
export class VariwideChartComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() seriesData: any[] = [];
  updateFlag = false;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;
  @Input() splineMax: number;

  constructor() { }

  ngOnInit(): void { }

  initializeChart() {
    if (this.seriesData && this.seriesData.length > 0) {
      this.chartOptions = {
        chart: {
          type: 'variwide',
          backgroundColor: 'none',
          height: 300,
          zoomType: 'xy'
        } as Highcharts.ChartOptions,
        title: {
          text: '',
          style: {
            display: 'none'
          }

        },
        credits: { enabled: false },
        exporting: { enabled: false },
        xAxis: [{
          type: 'category',
          title: {
            text: this.xAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            rotation: -35,
            format: '{value}',
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          }
        }, {
          //categories: true,
          min: 0,
          max: this.splineMax,
          visible: false
        }],
        yAxis: {
          title: {
            text: this.yAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            format: this.yAxisLabelFormat,
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          },
          gridLineColor: '#414257',
          gridLineDashStyle: 'Dash',
        },
        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          itemStyle: {
            color: '#8F8FAC',
            borderRadius: 5,
          },
          symbolRadius: 0,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style= padding:0>OTIF (%):{point.y}</td></tr>'
            + '<tr><td style= padding:0>{point.name}(M):{point.z}</td></tr>',
          footerFormat: '</table>',
          shared: false,
          useHTML: true
        },
        plotOptions:
        {
          variwide: {
            colors: ['#DA5BB3', '#8965CC', '#1275C1', '#1F893F', '#D07200', '#DA96C3', '#D07200', '#361C75', '#1D8583']
          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              inside: true,
              verticalAlign: 'top',
              y: 20,
              formatter: function () {
                return this.y + '%';
              },
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal'
              },
            },
          }
        },
        series: this.seriesData.slice()
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.seriesData) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }
}

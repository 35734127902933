import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  menuList: any[] = [];
  openSidebar: boolean = false;
  isSubmenuOpen: boolean = false;
  searchTerm: string = '';
  filteredMenuList: any[] = [];
  isHovered: boolean = false;

  @Input() set menuData(data: any) {
    if (data) {
      this.menuList = data;
    }
  }

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        this.menuList;
      });
  }

  ngOnInit() {
    if (this.menuList && Array.isArray(this.menuList)) {
      this.filteredMenuList = [...this.menuList];
    } else {
      console.error('MenuList is not an array or is undefined.');
    }
  }

  filterMenuList() {
    const term = this.searchTerm.toLowerCase();
    if (term.trim() === '') {
      // Reset to original menu list if search term is cleared
      this.filteredMenuList = [...this.menuList];
    } else {
      // Apply filtering
      this.filteredMenuList = this.includeParents(this.menuList, term);
    }
  }

  includeParents(items: any[], term: string): any[] {
    const result: any[] = [];
    function findMatchingItems(itemList: any[]): boolean {
      let hasMatch = false;
      for (const item of itemList) {
        const itemMatches = item.label.toLowerCase().includes(term);
        const childrenMatch = item.children ? findMatchingItems(item.children) : false;
        if (itemMatches || childrenMatch) {
          // Add only leaf nodes (nodes without children) to the result
          if (!item.children || item.children.length === 0) {
            result.push(item);
          }
          hasMatch = true;
        }
      }
      return hasMatch;
    }
    findMatchingItems(items);
    return result;
  }

  toggleSubmenuOpen(open: boolean) {
    this.isSubmenuOpen = open;
    this.openSidebar = open;
  }

  toggleSidebar(open: boolean) {
    if (!this.isSubmenuOpen) {
      this.openSidebar = open;
    } else {
      this.openSidebar = open;
    }
  }

  onMouseLeave() {
    // Close sidebar only if no submenu is open
    if (!this.isSubmenuOpen) {
      this.openSidebar = false;
    }
  }

}
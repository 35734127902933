import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent {
  @Input() options: { value: any, label: string, disabled?: boolean }[] = [];
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() selectedValue: any;
  @Output() option: EventEmitter<string> = new EventEmitter<string>();

  onClick(value: string) {
    this.option.emit(value);
  }
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() chartOptions: Highcharts.Options; // Input property for chart options
  updateFlag = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartOptions) {
      this.updateFlag = true;
    }
  }
  // Highcharts: typeof Highcharts = Highcharts;
  // updateFlag = false;
  // constructor() { }

  // ngOnInit(): void {
  // }

  // chartOptions: Highcharts.Options = {
  //   chart: {
  //     backgroundColor: '#131329',
  //     height: 370,
  //     //marginLeft: -50,
  //     type: 'pie'
  //   },
  //   title: {
  //     text: '',
  //     style: {
  //       display: 'none'
  //     }
  //   },
  //   subtitle: {
  //     text: '',
  //     style: {
  //       display: 'none'
  //     }
  //   },
  //   responsive: {
  //     rules: [{
  //       condition: {
  //         maxWidth: 500
  //       },
  //       chartOptions: {
  //         legend: {
  //           enabled: true
  //         }
  //       }
  //     }]
  //   },
  //   exporting: { enabled: false },
  //   credits: { enabled: false },
  //   colors: ['#B61FB0', '#6A009C', '#FFC700', '#FF6F00', '#FF0000', '#00FF00', '#0000FF', '#FF00FF', '#00FFFF', '#FFFF00', '#000000', '#FFFFFF'],
  //   legend: {
  //     layout: 'horizontal',
  //     align: 'center',
  //     verticalAlign: 'top',
  //     enabled: false,
  //     itemStyle: {
  //       color: '#8F8FAC'
  //     }
  //   },
  //   tooltip: {
  //     pointFormat: '<b>{point.percentage:.1f}%</b>'
  //   },
  //   accessibility: {
  //     point: {
  //       valueSuffix: '%'
  //     }
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: 'pointer',
  //       dataLabels: {
  //         enabled: true,
  //         distance: -30,
  //         format: '{point.percentage:.1f} %',
  //         style: {
  //           fontSize: '14px',
  //           color: '#A7A7DC'
  //         }
  //       },
  //       showInLegend: true
  //     },
  //     series: {
  //       showInLegend: true,
  //     }
  //   },
  //   series: [{
  //     type: 'pie',
  //     size: '100%',
  //     name: 'Brands',
  //     data: [{
  //       name: 'Procurement',
  //       y: 57.4
  //     }, {
  //       name: 'Production',
  //       y: 23.6
  //     }, {
  //       name: 'Logistics',
  //       y: 19.0
  //     }]

  //   }]
  // };
}

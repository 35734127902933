import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import variwide from 'highcharts/modules/variwide';
variwide(Highcharts);

@Component({
  selector: 'variwide-spline-chart',
  templateUrl: './variwide-spline-chart.component.html',
  styleUrls: ['./variwide-spline-chart.component.scss']
})
export class VariwideSplineChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() variwideData: any[] = [];
  @Input() splineData: any[] = [];
  updateFlag = false;
  @Input() enableLegend: boolean = true;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() yAxisLabel: string;
  @Input() xAxisLabel: string;
  @Input() splineMax: number;
  @Input() yAxisTickAmount = 6;
  @Input() firstYAxisLabel: string;
  @Input() secondaryYAxisLabel: string;

  constructor() { }
  ngOnInit(): void { }

  initializeChart() {
    if (this.variwideData && this.variwideData.length > 0) {
      this.chartOptions = {
        chart: {
          type: 'variwide',
          backgroundColor: 'none',
          height: 350,
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        xAxis: {
          type: 'category',
          categories: this.variwideData.map(point => point[0]),
          title: {
            text: this.xAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            rotation: -35,
            format: '{value}',
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          }
        },
        yAxis: [{
          title: {
            text: this.firstYAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            format: this.yAxisLabelFormat,
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          },
          min: 0,
          max: 100,
          tickAmount: this.yAxisTickAmount,
          gridLineColor: '#414257',
          gridLineDashStyle: 'Dash',
        },
        {
          title: {
            text: this.secondaryYAxisLabel,
            style: {
              color: '#C3C3CB',
              fontSize: '10px',
              fontFamily: 'EYInterstate-Regular'
            }
          },
          labels: {
            format: this.yAxisLabelFormat,
            style: {
              color: '#AFAEBA',
              //fontSize: '1em'
            }
          },
          min: 0,
          max: 100,
          tickAmount: this.yAxisTickAmount,
          gridLineColor: '#414257',
          gridLineDashStyle: 'Dash',
          opposite: true
        }],
        tooltip: {
          shared: true
        },
        legend: {
          enabled: this.enableLegend,
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',
          itemStyle: {
            color: '#8F8FAC',
            borderRadius: 5
          },
          itemHoverStyle: {
            color: '#fffff',
            cursor: 'pointer'
          },
          symbolRadius: 0,
        },
        plotOptions:
        {
          variwide: {
            colors: ['#DA5BB3', '#8965CC', '#1275C1', '#1F893F', '#D07200', '#DA96C3', '#D07200', '#361C75', '#1D8583'],
            colorByPoint: true,
            tooltip: {
              pointFormat: 'OTIF%: <b> {point.y}%</b><br>'
            },
            dataLabels: {
              enabled: true,
              y: 15,
              formatter: function () {
                return this.y + '%';
              },
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal'
              },
            }
          },
          spline: {
            color: '#ffffff',
            tooltip: {
              pointFormat: 'Gross Margin %: <b> {point.y}%</b><br>'
            },
            marker: {
              enabled: true
            },
            // Adjust spline x-values to be centered in variwide columns
            pointPlacement: 'on'
          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              //inside: false,
              //verticalAlign: 'top',
              y: 15,
              formatter: function () {
                return this.y + '%';
              },
              style: {
                color: '#f9f9fa',
                fontWeight: 'normal'
              },
            },
          }
        },
        series: [{
          type: 'variwide',
          name: 'OTIF%',
          data: this.variwideData
        }, {
          type: 'spline',
          name: 'Gross Margin %',
          data: this.splineData,
          yAxis: 1,
        }]
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variwideData) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }
}


import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);
import funnel from 'highcharts/modules/funnel';
funnel(Highcharts);

@Component({
  selector: 'funnel-chart',
  templateUrl: './funnel-chart.component.html',
  styleUrls: ['./funnel-chart.component.scss']
})
export class FunnelChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  @Input() seriesData;
  @Input() chartHeight;
  updateFlag = false;

  constructor() { }
  ngOnInit(): void {
    this.initializeChart();
  }

  initializeChart() {
    this.chartOptions = {
      chart: {
        backgroundColor: 'none',
        type: 'funnel',
        height: this.chartHeight
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      subtitle: {
        text: '',
        style: {
          display: 'none'
        }
      },
      responsive: {
        rules: [{
          condition: {
            //maxWidth: 500
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        funnel: {
          borderColor: '#181830',
        },
        series: {
          dataLabels: {
            enabled: true,
            inside: true,
            format: "<b>{point.name}</b> (${point.y:,.0f}M)",
            style: {
              textOutline: 'none',
              color: "black",
              fontSize: "9px",
              fontWeight: "normal"
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        format: "<b>{point.name} -</b> Revenue: (${point.y:,.0f}M)",
      },
      series: [
        {
          type: 'funnel',
          name: "Revenue",
          data: this.seriesData
        }
      ]
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.seriesData) {
      this.initializeChart();
      this.updateFlag = true;
    }
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LineChartOptions } from './line-chart-options';
import { LineChartData } from './line-chart-data';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { AppService } from 'src/app/services/app/app.service';
import { YAxisData } from '../apex-bar/y-axis-data';

@Component({
  selector: 'app-apex-line',
  templateUrl: './apex-line.component.html',
  styleUrls: ['./apex-line.component.scss']
})
export class ApexLineComponent implements OnInit, OnChanges {
  public chartOptions!: Partial<LineChartOptions>;
  @Input() LineChartData!: LineChartData;
  @Input() YAxisData!: YAxisData;
  @Input() TitleText!: string;

  constructor(private appService: AppService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['LineChartData'].firstChange) {
      this.chartOptions.series = changes['LineChartData'].currentValue['series'];
      let yaxisData = changes['YAxisData'].currentValue;

      this.chartOptions.yaxis = {
        labels: {
          style: {
            colors: '#fff',
          },
          formatter: function (val) {
            if (!val) {
              return '0'
            }
            return `${val}${yaxisData.suffixformattersymbol}`
          }
        },
        min: yaxisData.min,
        max: yaxisData.max,
        tickAmount: yaxisData.tickAmount,
        title: {
          text: yaxisData.ytitle,
          style: {
            color: '#fff'
          }
        }
      };
    }
  }

  ngOnInit(): void {

    let yaxisformatter = this.YAxisData.suffixformattersymbol;
    this.chartOptions = {
      series: this.LineChartData.series,
      chart: {
        height: 350,
        width: "100%",
        type: "line",
        
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false
        }
      },
      stroke: {
        curve: "straight"
      },
      grid: {
        strokeDashArray: 4,
        borderColor: '#414257',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      tooltip: {
        theme: 'dark'
      },
      labels: this.LineChartData.labels,
      title: {
        text: this.TitleText,
        style: {
          color: "#fff"
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A7A7DC',
            fontSize: '10px'
          },
          formatter: function (val) {
            if (!val) {
              return '0'
            }
            return `${val}${yaxisformatter}`
          }
        },
        min: this.YAxisData.min,
        max: this.YAxisData.max,
        tickAmount: this.YAxisData.tickAmount,
        title: {
          text: this.YAxisData.ytitle,
          style: {
            color: '#AFAEBA',
            // fontSize: '10px',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        }
      },
      xaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: '#A7A7DC',
            fontSize: '0.8em'
          }
        },
        title: {
          // text: this.YAxisData.xtitle,
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        }
      },
      legend: {
        show: true, // Set legend visibility here
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '10px',
        fontFamily: 'EYInterstate-Regular',
        labels: {
          colors: '#a7a7dc',
          useSeriesColors: false,
        },
      },
    };
    
  }
}

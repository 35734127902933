import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit
} from '@angular/core';
import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';
import { PowerBiService } from './powerBi.service';

@Component({
  selector: 'powerBi',
  templateUrl: './powerBi.component.html',
  styleUrls: ['./powerBi.component.scss']
})
export class powerBiComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @Input() embedUrl: string;
  @Input() id: string;
  @Input() accessToken: string;
  @Input() reportSection!: string;
  @Output() embedded = new EventEmitter<number>();
  @ViewChild('neoPowerBIIFrame', { static: true })
  neoPowerBIIFrameRef: ElementRef;

  // Private fields
  private component: pbi.Embed;
  private powerBiService: PowerBiService;
  getCurrentNavState: any;
  state: any;

  constructor(NeoPowerBIService: PowerBiService) {
    this.powerBiService = NeoPowerBIService;
  }
  ngOnInit(): void {

  }

  ngAfterViewInit() {
    // Embed the report inside the view child that we have fetched from the DOM
    if (
      this.neoPowerBIIFrameRef.nativeElement &&
      this.validateRequiredAttributes()
    ) {
      this.embed(this.neoPowerBIIFrameRef.nativeElement, this.getConfig());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.neoPowerBIIFrameRef) {
      return;
    }
    const {
      reportSection,
      accessToken,
      embedUrl,
      id,
    } = changes;

    if (this.validateRequiredAttributes()) {
      const config = this.getConfig(
        reportSection && reportSection.currentValue,
        accessToken && accessToken.currentValue,
        embedUrl && embedUrl.currentValue,
        id && id.currentValue,
      );
      this.embed(this.neoPowerBIIFrameRef.nativeElement, config);
    } else if (this.component) {
      this.reset(this.neoPowerBIIFrameRef.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.component) {
      this.reset(this.neoPowerBIIFrameRef.nativeElement);
    }
  }

  /**
   * Ensure required attributes (embedUrl and accessToken are valid before attempting to embed)
   */
  private validateRequiredAttributes(): boolean {
    return (
      typeof this.embedUrl === 'string' &&
      this.embedUrl.length > 0 &&
      (typeof this.accessToken === 'string' && this.accessToken.length > 0)
    );
  }

  /**
   * Returns an embed configuration object.
   * @param reportSection - Report section to be embedded
   * @param accessToken - Access token required to embed a component
   * @param tokenType - type of accessToken: Aad or Embed
   * @param embedUrl - Embed URL obtained through Power BI REST API or Portal
   * @param id - component/element GUID
   * @param type - type of embedded component e.g. 'dashboard, report or tile'
   * @param name - name of the embedded component
   * @param options - Embed configuration options
   * @returns Embed configuration object
   */
  private getConfig(
    reportSection?: string,
    accessToken?: string,
    embedUrl?: string,
    id?: string,
  ): pbi.IEmbedConfiguration {
    return {
      type: 'report',
      pageName: reportSection ? reportSection : this.reportSection,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken ? accessToken : this.accessToken,
      embedUrl: embedUrl ? embedUrl : this.embedUrl,
      id: id ? id : this.id,
      permissions: models.Permissions.All,
      viewMode: models.ViewMode.View,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Transparent,
        panes: {
          filters: {
            visible: false
          },
          pageNavigation: {
            visible: false
          }
        },
        bars: {
          statusBar: {
            visible: true
          }
        }
      }
    };
  }

  /**
   * Given an HTMLElement, construct an embed configuration based on attributes and pass to service.
   * @param element - native element where the embedding needs to be done
   * @param config - configuration to be embedded
   */
  private embed(element: HTMLElement, config: pbi.IEmbedConfiguration) {
    /*if (this.options) {
      const newConfig = { config, ...this.options };
    }*/

    this.component = this.powerBiService.embed(element, config);
    this.embedded.emit(this.component as any);
  }

  /**
   * Reset the component that has been removed from DOM.
   * @param element - native element where the embedded was made
   */
  reset(element: HTMLElement) {
    this.powerBiService.reset(element);
    this.component = null;
  }
}

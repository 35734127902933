import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts';

@Component({
  selector: 'bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss']
})
export class BubbleChartComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highchart
  updateFlag = false;
  @Input() seriesData;
  @Input() plotLinesData;
  @Input() xAxisTitle: string;
  @Input() yAxisTitle: string;
  @Input() percentageValChart: boolean;
  @Input() yAxisMin: number;
  @Input() yAxisMax: number;

  chartOptions: Highcharts.Options = {
    chart: {
      type: 'bubble',
      backgroundColor: '#131329',
      plotBorderWidth: 0,
      // borderColor: '#343445',
      //zoomType: 'xy',
      // width: 550,
      height: 300
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    responsive: {
      rules: [{
        condition: {
          // maxWidth: 400
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: {
      enabled: false
    },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. {point.name}, X: {point.x}, Y: {point.y}.'
      }
    },
    xAxis: {
      //min: 0,
      //max: 100,
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      gridLineWidth: .5,
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      labels: {
        allowOverlap: true,
        format: '{value}',
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      },
      accessibility: {
        rangeDescription: ''
      }
    },
    yAxis: {
      //min: 0,
      //max: 100,
      startOnTick: false,
      endOnTick: false,
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      labels: {
        allowOverlap: true,
        format: '{value}',
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      },
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      gridLineWidth: .5,
      maxPadding: 0.2,
      accessibility: {
        rangeDescription: ''
      }
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '<tr><th colspan="2"><p>{point.name}</p></th></tr>' +
        '<tr><th>Customers Impacted :</th><td>{point.y}</td></tr>' +
        '<tr><th>Orders Impacted :</th><td>{point.x}</td></tr>'
        + '<tr><th>Revenue Impacted :</th><td>{point.z}</td></tr>',
      footerFormat: '</table>',
      followPointer: true
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}',
          y: 20,
          style: {
            color: '#F9F9FA',
            fontSize: '8px',
            textOutline: 'none',
            fontWeight: 'EYInterstate-Light',
            textShadow: 'none',
          },
        }
      },
      bubble: {
        maxSize: '40',
        minSize: '20',
      }
    },

    series: [{
      type: 'bubble',
      data: []
    }]

  }

  constructor() { }
  ngOnInit() {
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
      //Highcharts.chart('bubble-chart', this.chartOptions).reflow();
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    const seriesData = changes.seriesData.currentValue;
    if (seriesData && seriesData.length > 0) {
      this.chartOptions.series = [{
        type: 'bubble',
        data: changes.seriesData.currentValue,
      }];
      (<Highcharts.AxisOptions>this.chartOptions.xAxis).title = {
        text: this.xAxisTitle,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      };
      (<Highcharts.AxisOptions>this.chartOptions.yAxis).title = {
        text: this.yAxisTitle,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      };
      if (this.percentageValChart) {
        (<Highcharts.AxisOptions>this.chartOptions.yAxis).labels.format = '{value}%';
        (<Highcharts.AxisOptions>this.chartOptions.xAxis).labels.format = '{value}%';
        this.chartOptions.tooltip.pointFormat = '<tr><th colspan="2"><p>{point.name}</p></th></tr>' +
          '<tr><th>On Time:</th><td>{point.x}%</td></tr>' +
          '<tr><th>In Full :</th><td>{point.y}%</td></tr>' +
          '<tr><th>Revenue :</th><td>{point.z}</td></tr>';
      }
      (<Highcharts.AxisOptions>this.chartOptions.yAxis).min = this.yAxisMin;
      (<Highcharts.AxisOptions>this.chartOptions.yAxis).max = this.yAxisMax;
      if (this.plotLinesData) {
        var addPlotLines = [];
        changes.plotLinesData.currentValue.forEach((el) => {
          addPlotLines.push({
            "color": "#afafba",
            "width": "1",
            "value": el.value,
            "zIndex": "5",
          });
          (<Highcharts.YAxisOptions>this.chartOptions.yAxis).plotLines = addPlotLines;
          (<Highcharts.XAxisOptions>this.chartOptions.xAxis).plotLines = addPlotLines;
        });
      };
    }
    this.updateFlag = true;
  }
}

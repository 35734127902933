<div class="row">
    <div class="col-12">
        <table mat-table class="datatablesku mat-elevation-z8" [dataSource]="dataSource">
            <ng-container matColumnDef="EmItemNum" sticky>
                <th mat-header-cell *matHeaderCellDef> 
                    Material No. 
                </th>
                <td mat-cell *matCellDef="let data; let i = index;" [attr.rowspan]="getRowSpan('EmItemNum', i)"
                    [style.display]="getRowSpan('EmItemNum', i) ? '' : 'none'">
                         <!-- <a [routerLink]="['/sku-details', data.EmItemNum]" >{{data.EmItemNum}}</a>  -->
                         <!-- <a [routerLink]="['/sku-details', data.EmItemNum]">{{data.EmItemNum}}</a> -->
                         <a (click)="navigateToDetails(data.EmItemNum)">{{data.EmItemNum}}</a>

                </td>
            </ng-container>

            <ng-container matColumnDef="Plant" sticky>
                <th mat-header-cell *matHeaderCellDef> Plant </th>
                <td mat-cell *matCellDef="let data; let i = index;" [attr.rowspan]="getRowSpan('EmItemNum', i)"
                    [style.display]="getRowSpan('EmItemNum', i) ? '' : 'none'">{{data.Plant}}</td>
            </ng-container>

            <ng-container matColumnDef="PartCriticality" sticky>
                <th mat-header-cell *matHeaderCellDef> Part Criticality </th>
                <td mat-cell *matCellDef="let data;let i = index;" [attr.rowspan]="getRowSpan('EmItemNum', i)"
                    [style.display]="getRowSpan('EmItemNum', i) ? '' : 'none'">
                    {{data.PartCriticality}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Values" sticky>
                <th mat-header-cell *matHeaderCellDef> Values </th>
                <td mat-cell *matCellDef="let data">{{data.Values}}</td>
            </ng-container>

            <ng-container matColumnDef="01-10-2023">
                <th mat-header-cell *matHeaderCellDef> 01-10-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-10-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-11-2023">
                <th mat-header-cell *matHeaderCellDef> 01-11-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-11-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-12-2023">
                <th mat-header-cell *matHeaderCellDef> 01-12-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-12-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-01-2024">
                <th mat-header-cell *matHeaderCellDef> 01-01-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-01-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-02-2024">
                <th mat-header-cell *matHeaderCellDef> 01-02-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-02-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-03-2024">
                <th mat-header-cell *matHeaderCellDef> 01-03-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-03-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-04-2024">
                <th mat-header-cell *matHeaderCellDef> 01-04-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-04-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-05-2024">
                <th mat-header-cell *matHeaderCellDef> 01-05-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-05-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-06-2024">
                <th mat-header-cell *matHeaderCellDef> 01-06-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-06-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-07-2024">
                <th mat-header-cell *matHeaderCellDef> 01-07-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-07-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-08-2024">
                <th mat-header-cell *matHeaderCellDef> 01-08-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-08-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-09-2024">
                <th mat-header-cell *matHeaderCellDef> 01-09-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-09-2024'] | customnumber}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <div class="col-12" >
        <div>
            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </div>
</div>
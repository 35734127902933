<div *ngIf="!show_parameter_table" class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-table">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>{{ getHeader(column) }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>


<div *ngIf="show_parameter_table" class="mat-elevation-z8 forecast-table-container">
    <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" class="mat-table" matSort>
            <!-- Header and columns definition -->
            <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="header-container">
                        <div class="header-text" (click)="toggleSearch(i)">
                            <p class="m-0">{{ getHeader(column) }}</p>
                            <mat-icon class="arrow-icon">{{ showSearch[i] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}</mat-icon>
                        </div>
                        <div class="header-buttons" *ngIf="isEditableHeader(column)">
                            <button mat-icon-button [disabled]="!isHoldingCostEditable" (click)="startEditingColumn(i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="isColumnEditable(i)" (click)="updateColumn(i)">
                                <mat-icon>check</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="isColumnEditable(i)" (click)="cancelEditingColumn(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                        <div class="dropdown-menu" [ngClass]="{'show': showSearch[i]}">
                            <!-- <mat-form-field appearance="standard">
                                <mat-icon matPrefix>search</mat-icon>
                                <input matInput (keyup)="applyFilter($event.target.value, column)"
                                    placeholder="Filter...">
                            </mat-form-field> -->
                            <mat-form-field appearance="standard">
                                <input matInput [(ngModel)]="filterValues[column]" placeholder="Filter..."
                                    autocomplete="off">
                            </mat-form-field>
                            <button class="search-btn no-padding" mat-raised-button color="primary"
                                (click)="applyFilter(column, 'applyFilter')" matTooltip="Search.."
                                matTooltipPosition="above">
                                <mat-icon matPrefix>search</mat-icon>
                            </button>
                            <button class="clear-btn no-padding" mat-raised-button color="primary"
                                (click)="clearFilter(column, 'clearFilter')" matTooltip="Clear.."
                                matTooltipPosition="above">
                                <mat-icon matPrefix>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element; let rowIndex = index">

                    <ng-container *ngIf="!isColumnEditable(i); else editMode">
                        <ng-container *ngIf="!isRowFieldEditable(element, column); else rowEditMode">
                            {{ element[column] }}
                        </ng-container>
                        <ng-template #rowEditMode>
                            <input type="text" [(ngModel)]="element[column]"
                                (ngModelChange)="onRowFieldValueChange($event, element, column)">
                        </ng-template>
                    </ng-container>
                    <ng-template #editMode>
                        <input type="text" [(ngModel)]="editableColumnValues[column]"
                            (ngModelChange)="onColumnValueChange($event, column)">
                    </ng-template>
                </td>

            </ng-container>

            <!-- Actions column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="sticky-column">Actions</th>
                <td mat-cell *matCellDef="let element" class="sticky-column">
                    <ng-container *ngIf="!isRowEditable(element); else editModeActions">
                        <button mat-raised-button (click)="startEditingRow(element)" class="edit-btn"
                            [disabled]="!allowParamEdit || !isIndividualUser">Edit</button>
                    </ng-container>
                    <ng-template #editModeActions>
                        <button mat-raised-button color="accent" class="update-action-button mr-3"
                            (click)="updateRow(element)">Update</button>
                        <button mat-raised-button color="warn" class="cancel-action-button"
                            (click)="cancelEditingRow(element)">Cancel</button>
                    </ng-template>
                </td>
            </ng-container>

            <!-- Table rows -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="mat-paginator-container"
            style="display: flex; justify-content: space-between; align-items: center;">
            <div *ngIf="showScenarioInput" class="additional-controls" style="display: flex;">
                <mat-form-field appearance="outline" style="width:400px">
                    <mat-label>Scenario Name</mat-label>
                    <input matInput placeholder="Scenario Name" [(ngModel)]="scenarioName">
                </mat-form-field>
                <button class="submit-btn" mat-raised-button (click)="submit()"
                    [disabled]="isSubmitDisabled() || !isIndividualUser">Submit</button>
            </div>
            <mat-paginator [length]="totalRecords" [pageSize]="50" (page)="onPaginateChange($event)">
            </mat-paginator>
        </div>
    </div>
    <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner class="loader" *ngIf="isLoading"></mat-spinner>
    </div>
</div>
<mat-sidenav-container class="ey-side-nav" autosize>
    <mat-sidenav color="primary" mode="side" opened (mouseenter)="toggleSidebar(true)" (mouseleave)="onMouseLeave()">
        <mat-nav-list>
            <div class="sidebar" [class.sidebar-close]="!openSidebar">
                <div class="search-container">
                    <mat-icon class="search-icon material-symbols-outlined">search</mat-icon>
                    <mat-form-field class="search-field" appearance="outline" *ngIf="openSidebar">
                        <input autocomplete="off" matInput placeholder="Search" [(ngModel)]="searchTerm"
                            (ngModelChange)="filterMenuList()" />
                    </mat-form-field>
                </div>
                <div class="nav-links" id="nav-links">
                    <hr class="separator-line">
                    <div class="menu-list" *ngFor="let item of filteredMenuList; let i = index"
                        routerLinkActive="active">
                        <hr class="separator-line" *ngIf="item.label == 'ASTERISK'" />
                        <div *ngIf="!item.children || item.children.length === 0">
                            <button mat-button [routerLink]="[item.routerLink]" class="menu-item">
                                <mat-icon class="menu-icon material-symbols-outlined">{{item.icon}}</mat-icon>
                                <span *ngIf="openSidebar" class="link_name">{{ item.label }}</span>
                            </button>
                        </div>
                        <div *ngIf="item.children && item.children.length > 0" class="child-item">
                            <div class="split-button-container">
                                <button mat-button [routerLink]="[item.routerLink]" class="menu-item">
                                    <mat-icon class="menu-icon material-symbols-outlined">{{item.icon}}</mat-icon>
                                    <span *ngIf="openSidebar" class="link_name">{{ item.label }}</span>
                                </button>
                                <button mat-menu-item [matMenuTriggerFor]="panel.menu" class="split-button"
                                    (menuOpened)="toggleSubmenuOpen(true)" (menuClosed)="toggleSubmenuOpen(false)">
                                    <mat-icon *ngIf="openSidebar"
                                        class="arrow_right material-symbols-outlined">arrow_right</mat-icon>
                                </button>
                            </div>
                            <app-menu-item #panel [items]="item.children"></app-menu-item>
                        </div>
                    </div>
                    <hr class="separator-line" />
                </div>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="side-content" [style.margin-left.px]="isHovered ? 10 : 50">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexPlotOptions, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { BarChartGroupData } from './bar-chart-group-data';
import { AppService } from 'src/app/services/app/app.service';

export type BarGroupChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  annotations: ApexAnnotations;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-bar-chart-group',
  templateUrl: './bar-chart-group.component.html',
  styleUrls: ['./bar-chart-group.component.scss']
})



export class BarChartGroupComponent implements OnInit {
  @Input() chartHeight: string = "";


  public chartOptions!: Partial<BarGroupChartOptions>;
  @Input() data!: BarChartGroupData;
  constructor(private appService: AppService) { }


  ngOnInit(): void {
    let dataLablesFormat = this.data.dataLabelsymbol;
    let ysymbol = this.data.yaxisData?.suffixformattersymbol;
    // let seriesColors = ['#35A4E8', '#188CE5'];
    // console.log('Series Colors:', seriesColors);
    this.chartOptions = {
      series: this.data.series,
      legend: {
        show: true, // Set legend visibility here
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '10px',
        fontFamily: 'EYInterstate-Regular',
        labels: {
          colors: '#a7a7dc',
          useSeriesColors: false,
        },
      },
      chart: {
        type: "bar",
        height: this.chartHeight,
        //   width: "88%",
        toolbar: {
          show: false
        }
      },


      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          distributed: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        offsetY: -30,
        formatter: function (val) {
          return `${val}${dataLablesFormat}`
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: this.data.labels,
        axisBorder: {
          show: true,
          color: '#414257'
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: '#AFAEBA',
            fontSize: '0.8em',
          },
        },
        title: {
          text: this.data.yaxisData?.xtitle,
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#AFAEBA',
            fontSize: '0.8em',
          },
          formatter: function (val) {
            return `${val}${ysymbol}`
          }
        },
        min: this.data.yaxisData?.min,
        max: this.data.yaxisData?.max,
        tickAmount: this.data.yaxisData?.tickAmount,
        title: {
          offsetX: 12,
          text: this.data.yaxisData?.ytitle,
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        }
      },
      fill: {
        opacity: 1,
        // colors: ['#35A4E8', '#188CE5'],
      },
      tooltip: {
        y: {
          title: {
            formatter: function (val) {
              return "";
            }
          }
        },
        theme: 'dark'
      },
      grid: {
        strokeDashArray: 4,
        borderColor: '#414257',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      title: {
        text: this.data.Title,
        style: {
          color: "#A7A7DC",
        }
      },
      annotations: {
        yaxis: this.data.annotation
      }
    };
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  menuList: any;
  openSidebar: boolean = false;
  isHovered: boolean = false;
  isExpanded: boolean = true;

  @Input() set menuData(data: any) {
    if (data) {
      this.menuList = data;
    }
  }

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        this.menuList;
      });
  }

  ngOnInit() { }

  getMatIcon(index: number): string {
    const iconName = [
      'home',
      'view_module',
      'polymer',
      'settings',
      'monitoring',
      'payments',
      'inventory_2',
      'shopping_cart',
      'local_police',
      'change_circle',
      'live_help',
      'emergency',
      'star',
      'captive_portal'
    ];
    return iconName[index % iconName.length];
  }

  showSubmenu(itemEl: HTMLElement) {
    itemEl.classList.toggle('showMenu');
  }

  showLeafMenu(leafEl: HTMLElement) {
    leafEl.classList.toggle('showSubMenu');
  }

  showLeafSubMenu(leafSubMenuEl: HTMLElement) {
    leafSubMenuEl.classList.toggle('showLeafMenu');
  }

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
    if (!this.isHovered) {
      this.isExpanded = false; // Collapse sidebar when mouse leaves
    }
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() options: { label: string, value: string }[] = [];
  @Input() selectedOption: string;
  @Output() selectedOptionChange = new EventEmitter<string>();

  selectOption(option: string): void {
    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
  }
  constructor() { }

  ngOnInit(): void {
  }

}

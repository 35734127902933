<div class="row">
    <div class="col-md-12 p-0">
        <table mat-table class="skudetail mat-elevation-z8" [dataSource]="dataSource">

            <ng-container matColumnDef="Values" sticky>
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let data">{{data.Values}}</td>
            </ng-container>

            <ng-container matColumnDef="01-10-2023">
                <th mat-header-cell *matHeaderCellDef> 01-10-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-10-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-11-2023">
                <th mat-header-cell *matHeaderCellDef> 01-11-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-11-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-12-2023">
                <th mat-header-cell *matHeaderCellDef> 01-12-2023 </th>
                <td mat-cell *matCellDef="let data">{{data['01-12-2023'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-01-2024">
                <th mat-header-cell *matHeaderCellDef> 01-01-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-01-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-02-2024">
                <th mat-header-cell *matHeaderCellDef> 01-02-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-02-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-03-2024">
                <th mat-header-cell *matHeaderCellDef> 01-03-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-03-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-04-2024">
                <th mat-header-cell *matHeaderCellDef> 01-04-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-04-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-05-2024">
                <th mat-header-cell *matHeaderCellDef> 01-05-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-05-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-06-2024">
                <th mat-header-cell *matHeaderCellDef> 01-06-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-06-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-07-2024">
                <th mat-header-cell *matHeaderCellDef> 01-07-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-07-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-08-2024">
                <th mat-header-cell *matHeaderCellDef> 01-08-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-08-2024'] | customnumber}}</td>
            </ng-container>

            <ng-container matColumnDef="01-09-2024">
                <th mat-header-cell *matHeaderCellDef> 01-09-2024 </th>
                <td mat-cell *matCellDef="let data">{{data['01-09-2024'] | customnumber}}</td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @Input() getBarChartData: any[] = [];
  @Input() view: any[] = [];
  @Input() colorScheme;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  @Input() xAxisLabel;
  showYAxisLabel = true;
  @Input() yAxisLabel;

  constructor() { }

  ngOnInit(): void { }

}

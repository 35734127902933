import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-line-chart',
  templateUrl: './ngx-line-chart.component.html',
  styles: ['']
})

export class NgxLineChartComponent implements OnInit {
  @Input() getLineChartData: any[] = [];
  @Input() colorScheme;
  @Input() showLegend;
  @Input() showXAxisLabel;
  @Input() xAxisLabel;
  @Input() showYAxisLabel;
  @Input() yAxisLabel;
  @Input() view: any[] = [];
  xAxis: boolean = true;
  yAxis: boolean = true;
  autoScale = false;

  constructor() { }
  ngOnInit() { }
}

import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'multiple-kpi-gauge',
  templateUrl: './multiple-kpi-gauge.component.html',
  styleUrls: ['./multiple-kpi-gauge.component.scss']
})
export class MultipleKpiGaugeComponent implements OnInit {
  Highcharts = Highcharts;
  @Input() seriesData;
  @Input() paneBackground = [];
  updateFlag: boolean;

  chartOptions = {
    chart: {
      backgroundColor: 'none',
      type: 'solidgauge',
      height: '80%'
    },
    title: {
      text: '',
      style: {
        fontSize: '12px'
      }
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    tooltip: {
      borderWidth: 0,
      backgroundColor: 'none',
      shadow: false,
      style: {
        color: '#A7A7DC',
        fontSize: '1em'
      },
      valueSuffix: '%',
      pointFormat:
        '{series.name}<br><span style="font-size:12px; color: {point.color}; font-weight: bold">{point.y}</span>',
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2 + 15
        };
      }
    },

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: this.paneBackground,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: true,
          padding: 0,
          x: 0
        },
        stickyTracking: true,
        rounded: true
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },
    dataLabels: {
      overflow: 'none',
      crop: false,
      borderWidth: 0,
      formatter: function () {
        return this.series.name;
      },
      y: 0,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        width: '50px',
        textAnchor: 'middle'
      }
    },

    series: []
  };

  ngOnInit(): void {
    this.chartOptions.series = [
      {
        name: '',
        data: []
      }
    ];
    this.loadBidChartData();
    //  setInterval(()=>this.loadBidChartData(),1000);
  }

  loadBidChartData(): void {
    this.chartOptions.series = this.seriesData;
    this.chartOptions.pane.background = this.paneBackground;
    this.updateFlag = true;
  }
}

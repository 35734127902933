import { Component, Input, NgZone, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions } from './chart-options';
import { XAxisData } from './x-axis-data';
import { YAxisData } from './y-axis-data';
import { YAxisAnnotation } from './Y-axis-annotation';
import * as ApexCharts from 'apexcharts';
import { debounceTime, delay, shareReplay, take, takeLast } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app/app.service';

@Component({
  selector: 'app-apex-bar',
  templateUrl: './apex-bar.component.html',
  styleUrls: ['./apex-bar.component.scss']
})
export class ApexBarComponent implements OnInit {

  @Input() XAxisData!: XAxisData[];
  @Input() ShowValueOnBar: boolean = false;
  @Input() ShowDownloadOption: boolean = false;
  @Input() ShowGridLines: boolean = true;
  @Input() ShowLableColor: string = "#AFAEBA";
  @Input() YAxisData!: YAxisData;
  @Input() YAxisAnnotation: YAxisAnnotation | undefined;
  @Input() colors: string[] = [];
  @Input() ChartTitle!: string;
  @Input() showLegend: boolean = false; // Default is true, meaning legend is shown
  @Input() chartWidth: string = "";
  @Input() chartHeight: string = "";
  @Input() rotateAlways: boolean = false;
  // @Input() barColors: string[] = [];



  // @ViewChild("chart") chart: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;


  constructor(private appService: AppService, private router: Router, private zone: NgZone) {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['XAxisData'].firstChange) {
      this.chartOptions.series = [
        {
          name: "",
          data: changes['XAxisData'].currentValue.map((x: XAxisData) => ({
            x: x.name,
            y: x.value
          }))
        }
      ];
    }
  }

  ngOnInit(): void {
    let barTitle = this.ChartTitle;
    let router = this.router;
    let formatterSymbol = this.YAxisData.suffixformattersymbol;
    this.chartOptions = {
      series: [
        {
          name: "",
          data: this.XAxisData.map((x, index) => ({
            x: x.name,
            y: x.value,
            // Default color if not provided

          }))
        }
      ],
      legend: {
        show: this.showLegend,
        fontSize: '10px',
        fontFamily: 'EYInterstate-Regular',
        labels: {
          colors: '#a7a7dc',
          useSeriesColors: false,
        },
      },


      chart: {
        type: "bar",
        height: this.chartHeight,
        // width: '100%',
        toolbar: {
          show: this.ShowDownloadOption
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            let barName = config.w.config.series[0].data[config.dataPointIndex].x;

            if (barTitle === 'Plant Forecast Accuracy' && barName === 'Ohio') {
              this.zone.run(() => {
                router.navigate(['/home/forecast'], { queryParams: { plant: barName } });
              });
            }
          }

        }
      },


      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadiusApplication: 'around',
          borderRadiusWhenStacked: "all",
          borderRadius: 5,
          distributed: true,
          dataLabels: {

            // position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: this.ShowValueOnBar,
        offsetY: -15,
        formatter: function (val) {
          return `${val}%`
        },
        style: {
          fontSize: '10'
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: this.XAxisData.map(x => x.name),
        axisBorder: {
          show: this.ShowGridLines,
          color: '#414257'
        },
        axisTicks: {
          show: false
        },
        title: {
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
        labels: {
          style: {
            colors: this.ShowLableColor,
            fontSize: '0.8em',
          },
          rotate: -45,
          rotateAlways: this.rotateAlways
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: this.ShowLableColor,
            fontSize: '0.8em',
          },
          formatter: function (val) {
            return `${val}${formatterSymbol}`
          }
        },
        min: this.YAxisData.min,
        max: this.YAxisData.max,
        title: {
          text: this.YAxisData.ytitle,
          style: {
            color: '#AFAEBA',
            // fontSize: '10px',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
        tickAmount: this.YAxisData.tickAmount
      },
      fill: {
        opacity: 1,
        colors: this.colors,
      },
      tooltip: {
        y: {
          title: {
            formatter: function (val) {
              return "";
            }
          }
        },
        theme: 'dark'
      },
      grid: {
        strokeDashArray: 4,
        borderColor: '#414257',
        // xaxis: {
        //   lines: {
        //     show: this.ShowGridLines
        //   }
        // },
        yaxis: {
          lines: {
            show: this.ShowGridLines,
          }
        }
      },
      title: {
        text: '',
        style: {
          color: "#A7A7DC",
        }
      },
      annotations: !this.YAxisAnnotation ? undefined : {
        yaxis: [
          {
            y: this.YAxisAnnotation.target,
            borderWidth: this.YAxisAnnotation.borderLineWidth,
            borderColor: '#afafba',
            strokeDashArray: 0,
            label: {
              style: {
                fontSize: "10",
                fontWeight: 'bold',
                color: this.ShowLableColor,
                background: 'transparent',
              },
              borderWidth: 0,
              text: this.YAxisAnnotation.text
            },
          }
        ]
      }
    };

  }
}

<div class="header">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>
<hr>
<div class="body-txt">
    "Access Denied - Please contact EY Supply Chain Vault Support".<br>
    <a class="click-here" (click)="requestAccess()">Click Here</a> to request access.
</div>
<hr>
<div class="footer">
    <button mat-button mat-dialog-close (click)="onCancel()" class="cancel-btn">Close</button>
</div>
<div class="data-count-header row">
    <div class="col-md-12 action-dropdowns col-lg-12 d-flex count justify-content-between">
        <div class="page-grid">
            <!-- <span>Total No of SKUs</span> -->
            <span>{{firstCardHeading}}</span>
            <span class="font-bold">{{ firstCardValue }}</span>
        </div>
        <div class="page-grid">
            <!-- <span>Critical SKUs</span> -->
            <span>{{secondCardHeading}}</span>
            <span class="font-bold">{{ secondCardValue }}</span>
        </div>
        <div class="page-grid">
            <!-- <span>Non-Critical SKUs</span> -->
            <span>{{thirdCardHeading}}</span>
            <span class="font-bold">{{ thirdCardValue }}</span>
        </div>
    </div>

</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() firstCardHeading;
  @Input() secondCardHeading;
  @Input() thirdCardHeading;
  @Input() firstCardValue: number = 0;
  @Input() secondCardValue: number = 0;
  @Input() thirdCardValue: number = 0;
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class SKUDetailService {

  requiredFormat!: any[];

  data1: SkuDetails[] = [
    {
      EmItemNum: '1102763',
      Plant: 'Arizona (CMO 1)',
      PartCriticality: 'Critical',
      Name: 'Sales History',
      Data: [{
        date: '01-10-2023',
        value: '1599'
      },
      {
        date: '01-11-2023',
        value: '2061'
      },
      {
        date: '01-12-2023',
        value: '1559'
      },
      {
        date: '01-01-2024',
        value: '2031'
      },
      {
        date: '01-02-2024',
        value: '1747'
      },
      {
        date: '01-03-2024',
        value: '1801'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1102763',
      Plant: 'Arizona (CMO 1)',
      PartCriticality: 'Critical',
      Name: 'Best Fit Forecast',
      Data: [{
        date: '01-10-2023',
        value: '1548'
      },
      {
        date: '01-11-2023',
        value: '1738'
      },
      {
        date: '01-12-2023',
        value: '1861'
      },
      {
        date: '01-01-2024',
        value: '1897'
      },
      {
        date: '01-02-2024',
        value: '1945'
      },
      {
        date: '01-03-2024',
        value: '2096'
      },
      {
        date: '01-04-2024',
        value: '1893'
      },
      {
        date: '01-05-2024',
        value: '1533'
      },
      {
        date: '01-06-2024',
        value: '1699'
      },
      {
        date: '01-07-2024',
        value: '1619'
      },
      {
        date: '01-08-2024',
        value: '1559'
      },
      {
        date: '01-09-2024',
        value: '1969'
      }
      ]
    },
    {
      EmItemNum: '1102763',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Name: 'Forecast Accuracy',
      Data: [{
        date: '01-10-2023',
        value: '97'
      },
      {
        date: '01-11-2023',
        value: '84'
      },
      {
        date: '01-12-2023',
        value: '81'
      },
      {
        date: '01-01-2024',
        value: '93'
      },
      {
        date: '01-02-2024',
        value: '89'
      },
      {
        date: '01-03-2024',
        value: '84'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Name: 'Sales History',
      Data: [{
        date: '01-10-2023',
        value: '124'
      },
      {
        date: '01-11-2023',
        value: '145'
      },
      {
        date: '01-12-2023',
        value: '167'
      },
      {
        date: '01-01-2024',
        value: '140'
      },
      {
        date: '01-02-2024',
        value: '165'
      },
      {
        date: '01-03-2024',
        value: '152'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Name: 'Best Fit Forecast',
      Data: [{
        date: '01-10-2023',
        value: '167'
      },
      {
        date: '01-11-2023',
        value: '121'
      },
      {
        date: '01-12-2023',
        value: '115'
      },
      {
        date: '01-01-2024',
        value: '97'
      },
      {
        date: '01-02-2024',
        value: '94'
      },
      {
        date: '01-03-2024',
        value: '150'
      },
      {
        date: '01-04-2024',
        value: '133'
      },
      {
        date: '01-05-2024',
        value: '149'
      },
      {
        date: '01-06-2024',
        value: '126'
      },
      {
        date: '01-07-2024',
        value: '149'
      },
      {
        date: '01-08-2024',
        value: '102'
      },
      {
        date: '01-09-2024',
        value: '113'
      }
      ]
    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Name: 'Forecast Accuracy',
      Data: [{
        date: '01-10-2023',
        value: '65'
      },
      {
        date: '01-11-2023',
        value: '84'
      },
      {
        date: '01-12-2023',
        value: '69'
      },
      {
        date: '01-01-2024',
        value: '69'
      },
      {
        date: '01-02-2024',
        value: '57'
      },
      {
        date: '01-03-2024',
        value: '99'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Name: 'Sales History',
      Data: [{
        date: '01-10-2023',
        value: '75'
      },
      {
        date: '01-11-2023',
        value: '56'
      },
      {
        date: '01-12-2023',
        value: '62'
      },
      {
        date: '01-01-2024',
        value: '73'
      },
      {
        date: '01-02-2024',
        value: '69'
      },
      {
        date: '01-03-2024',
        value: '59'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Name: 'Best Fit Forecast',
      Data: [{
        date: '01-10-2023',
        value: '55'
      },
      {
        date: '01-11-2023',
        value: '61'
      },
      {
        date: '01-12-2023',
        value: '70'
      },
      {
        date: '01-01-2024',
        value: '73'
      },
      {
        date: '01-02-2024',
        value: '79'
      },
      {
        date: '01-03-2024',
        value: '95'
      },
      {
        date: '01-04-2024',
        value: '78'
      },
      {
        date: '01-05-2024',
        value: '70'
      },
      {
        date: '01-06-2024',
        value: '53'
      },
      {
        date: '01-07-2024',
        value: '54'
      },
      {
        date: '01-08-2024',
        value: '55'
      },
      {
        date: '01-09-2024',
        value: '51'
      }
      ]
    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Name: 'Forecast Accuracy',
      Data: [{
        date: '01-10-2023',
        value: '74'
      },
      {
        date: '01-11-2023',
        value: '89'
      },
      {
        date: '01-12-2023',
        value: '87'
      },
      {
        date: '01-01-2024',
        value: '100'
      },
      {
        date: '01-02-2024',
        value: '85'
      },
      {
        date: '01-03-2024',
        value: '37'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Name: 'Sales History',
      Data: [{
        date: '01-10-2023',
        value: '269'
      },
      {
        date: '01-11-2023',
        value: '232'
      },
      {
        date: '01-12-2023',
        value: '274'
      },
      {
        date: '01-01-2024',
        value: '223'
      },
      {
        date: '01-02-2024',
        value: '194'
      },
      {
        date: '01-03-2024',
        value: '292'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Name: 'Best Fit Forecast',
      Data: [{
        date: '01-10-2023',
        value: '193'
      },
      {
        date: '01-11-2023',
        value: '211'
      },
      {
        date: '01-12-2023',
        value: '235'
      },
      {
        date: '01-01-2024',
        value: '245'
      },
      {
        date: '01-02-2024',
        value: '258'
      },
      {
        date: '01-03-2024',
        value: '293'
      },
      {
        date: '01-04-2024',
        value: '185'
      },
      {
        date: '01-05-2024',
        value: '271'
      },
      {
        date: '01-06-2024',
        value: '194'
      },
      {
        date: '01-07-2024',
        value: '294'
      },
      {
        date: '01-08-2024',
        value: '261'
      },
      {
        date: '01-09-2024',
        value: '224'
      }
      ]
    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Name: 'Forecast Accuracy',
      Data: [{
        date: '01-10-2023',
        value: '74'
      },
      {
        date: '01-11-2023',
        value: '91'
      },
      {
        date: '01-12-2023',
        value: '86'
      },
      {
        date: '01-01-2024',
        value: '90'
      },
      {
        date: '01-02-2024',
        value: '67'
      },
      {
        date: '01-03-2024',
        value: '100'
      },
      {
        date: '01-04-2024',
        value: ''
      },
      {
        date: '01-05-2024',
        value: ''
      },
      {
        date: '01-06-2024',
        value: ''
      },
      {
        date: '01-07-2024',
        value: ''
      },
      {
        date: '01-08-2024',
        value: ''
      },
      {
        date: '01-09-2024',
        value: ''
      }
      ]
    }
  ];



  data = [
    {
      EmItemNum: '1102763',
      Plant: 'Arizona (CMO 1)',
      PartCriticality: 'Critical',
      Values: 'Sales History',
      '01-10-2023': '1599',
      '01-11-2023': '2061',
      '01-12-2023': '1559',
      '01-01-2024': '2031',
      '01-02-2024': '1747',
      '01-03-2024': '1801',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1102763',
      Plant: 'Arizona (CMO 1)',
      PartCriticality: 'Critical',
      Values: 'Best Fit Forecast',
      '01-10-2023': '1548',
      '01-11-2023': '1738',
      '01-12-2023': '1861',
      '01-01-2024': '1897',
      '01-02-2024': '1945',
      '01-03-2024': '2096',
      '01-04-2024': '1893',
      '01-05-2024': '1533',
      '01-06-2024': '1699',
      '01-07-2024': '1619',
      '01-08-2024': '1559',
      '01-09-2024': '1969',

    },
    {
      EmItemNum: '1102763',
      Plant: 'Arizona (CMO 1)',
      PartCriticality: 'Critical',
      Values: 'Forecast Accuracy',
      '01-10-2023': '97',
      '01-11-2023': '84',
      '01-12-2023': '81',
      '01-01-2024': '93',
      '01-02-2024': '89',
      '01-03-2024': '84',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Values: 'Sales History',
      '01-10-2023': '124',
      '01-11-2023': '145',
      '01-12-2023': '167',
      '01-01-2024': '140',
      '01-02-2024': '165',
      '01-03-2024': '152',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Values: 'Best Fit Forecast',
      '01-10-2023': '167',
      '01-11-2023': '121',
      '01-12-2023': '115',
      '01-01-2024': '97',
      '01-02-2024': '94',
      '01-03-2024': '150',
      '01-04-2024': '133',
      '01-05-2024': '149',
      '01-06-2024': '126',
      '01-07-2024': '149',
      '01-08-2024': '102',
      '01-09-2024': '113',

    },
    {
      EmItemNum: '1123223',
      Plant: 'Ohio (CMO 2)',
      PartCriticality: 'Critical',
      Values: 'Forecast Accuracy',
      '01-10-2023': '65',
      '01-11-2023': '84',
      '01-12-2023': '69',
      '01-01-2024': '69',
      '01-02-2024': '57',
      '01-03-2024': '99',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Values: 'Sales History',
      '01-10-2023': '75',
      '01-11-2023': '56',
      '01-12-2023': '62',
      '01-01-2024': '73',
      '01-02-2024': '69',
      '01-03-2024': '59',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Values: 'Best Fit Forecast',
      '01-10-2023': '55',
      '01-11-2023': '61',
      '01-12-2023': '70',
      '01-01-2024': '73',
      '01-02-2024': '79',
      '01-03-2024': '95',
      '01-04-2024': '78',
      '01-05-2024': '70',
      '01-06-2024': '53',
      '01-07-2024': '54',
      '01-08-2024': '55',
      '01-09-2024': '51',

    },
    {
      EmItemNum: '1125525',
      Plant: 'California',
      PartCriticality: 'Critical',
      Values: 'Forecast Accuracy',
      '01-10-2023': '74',
      '01-11-2023': '89',
      '01-12-2023': '87',
      '01-01-2024': '100',
      '01-02-2024': '85',
      '01-03-2024': '37',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Values: 'Sales History',
      '01-10-2023': '269',
      '01-11-2023': '232',
      '01-12-2023': '274',
      '01-01-2024': '223',
      '01-02-2024': '194',
      '01-03-2024': '292',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Values: 'Best Fit Forecast',
      '01-10-2023': '193',
      '01-11-2023': '211',
      '01-12-2023': '235',
      '01-01-2024': '245',
      '01-02-2024': '258',
      '01-03-2024': '293',
      '01-04-2024': '185',
      '01-05-2024': '271',
      '01-06-2024': '194',
      '01-07-2024': '294',
      '01-08-2024': '261',
      '01-09-2024': '224',

    },
    {
      EmItemNum: '1134824',
      Plant: 'Texas',
      PartCriticality: 'Critical',
      Values: 'Forecast Accuracy',
      '01-10-2023': '72',
      '01-11-2023': '91',
      '01-12-2023': '86',
      '01-01-2024': '90',
      '01-02-2024': '67',
      '01-03-2024': '100',
      '01-04-2024': '',
      '01-05-2024': '',
      '01-06-2024': '',
      '01-07-2024': '',
      '01-08-2024': '',
      '01-09-2024': '',

    }
  ];
}

export interface SkuDetails {
  EmItemNum: string;
  Plant: string;
  PartCriticality: string;
  Name: string;
  Data: SkuDetailsData[];
}

export interface SkuDetailsData {
  date: string;
  value: string;
}
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {

  private sideNavtracker:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
  ngOnDestroy(): void {
    this.getNavtracker().unsubscribe();
  }

  setNavtracker(value: boolean) {
    this.sideNavtracker.next(value);
  }

  getNavtracker() {
    return this.sideNavtracker;
  }
}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import { MarkerService } from './marker.service';
import { ShapeService } from './shape.service';

const iconRetinaUrl = 'assets/demand-forecast/data/marker-icon-2x.png';
const iconUrl = 'assets/demand-forecast/data/marker-icon.png';
const shadowUrl = 'assets/demand-forecast/data/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = iconDefault;


@Component({
  selector: 'app-map-forecast-chart',
  templateUrl: './map-forecast-chart.component.html',
  styleUrls: ['./map-forecast-chart.component.scss']
})
export class MapForecastChartComponent implements AfterViewInit, OnDestroy {
  private map: any;
  private states: any;

  private highlightFeature(e: any) {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      opacity: 1.0,
      color: '#df9800',
      fillOpacity: 1.0,
      fillColor: '#f8a900',
    });
  }

  private resetFeature(e: any) {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      opacity: 0.3,
      color: '#8169ad',
      fillOpacity: 0.8,
      fillColor: '#a68ed2',
    });
  }

  private initStatesLayer() {
    const stateLayer = L.geoJSON(this.states, {
      style: (feature) => ({
        weight: 2,
        opacity: 0.3,
        color: '#8169ad',
        fillOpacity: 0.8,
        fillColor: '#a68ed2',
      }),
      onEachFeature: (feature, layer) =>
        layer.on({
          mouseover: (e) => this.highlightFeature(e),
          mouseout: (e) => this.resetFeature(e),
        }),
    });

    this.map.addLayer(stateLayer);
    stateLayer.bringToBack();
  }

  constructor(
    private markerService: MarkerService,
    private shapeService: ShapeService
  ) {}

  ngOnDestroy(): void {}

  private initMap(): void {
    this.map = L.map('map', {
      center: [37.8282, -100.5795],
      zoom: 4,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 4,
        minZoom: 4,
        attribution: 'EY',
      }
    );

    tiles.addTo(this.map);
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.markerService.makeCapitalMarkers(this.map);
    this.shapeService.getStateShapes().subscribe((states) => {
      this.states = states;
      this.initStatesLayer();
    });
    setTimeout(() => {
      this.map.invalidateSize();
    }, 1000);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RequestAccessComponent } from '../../request-access/request-access.component';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {

  constructor(private msalService: MsalService,
    private dialogRef: MatDialogRef<DialogBoxComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { username: string, useremail: string }) {

    if (!this.data) {
      this.data = { username: '', useremail: '' };
    }
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  requestAccess(): void {
    const dialogRef1 = this.dialog.open(RequestAccessComponent, {
      data: {
        username: this.msalService.instance.getActiveAccount().name,
        email: this.msalService.instance.getActiveAccount().username
      }
    });
    dialogRef1.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    this.dialogRef.close();
  }

}

import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MENU_DATA } from 'src/app/constants/menu.constants';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    isDark = true;
    selectedCategory: any;
    menuData;

    constructor(private msalService: MsalService,
        private router: Router,
        private overlayContainer: OverlayContainer,
        private readonly utilsService: UtilsService) {
        const roleId = this.utilsService.getRoleId();
        this.getMenuDataBasedOnRole(roleId);
    }

    getMenuDataBasedOnRole(role): void {
        this.menuData = MENU_DATA[role];
        this.utilsService.menuData = this.menuData;
    }

    ngOnInit(): void {
    }

    toggleTheme(): void {
        this.isDark = !this.isDark;
        if (!this.isDark) {
            this.overlayContainer.getContainerElement().classList.add('light-theme');
        } else {
            this.overlayContainer
                .getContainerElement()
                .classList.remove('light-theme');
        }
    }

    logout() {
        this.msalService.logout().subscribe(() => {
            this.msalService.loginRedirect(); // Trigger login after logout completes
        });
        this.router.navigate(["/logout"]);
    }

}

<mat-card class="basic-card">
    <mat-card-title>
        <ng-content select="[title]"></ng-content>
    </mat-card-title>
    <mat-card-content>
        <ng-content select="[body]"></ng-content>
    </mat-card-content>
    <mat-card-actions>
        <ng-content select="[action]"></ng-content>
    </mat-card-actions>
</mat-card>
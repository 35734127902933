<mat-menu #menu xPosition="after" yPosition="below" [overlapTrigger]="false" class="mat-menu">
    <ng-container *ngFor="let item of items">
        <div *ngIf="!item.children || item.children.length === 0; else branch">
            <button [routerLink]="[item.routerLink]" mat-menu-item class="menu-item">
                <mat-icon class="menu-icon material-symbols-outlined">{{item.icon}}</mat-icon>
                <span class="link_name">{{ item.label }}</span>
            </button>
        </div>
        <ng-template #branch>
            <button mat-menu-item [matMenuTriggerFor]="panel.menu" class="menu-item">
                <mat-icon class="menu-icon material-symbols-outlined">{{item.icon}}</mat-icon>
                <span class="link_name">{{ item.label }}</span>
            </button>
            <app-menu-item #panel [items]="item.children"></app-menu-item>
        </ng-template>
    </ng-container>
</mat-menu>
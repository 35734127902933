
<div id="chart">
    <apx-chart
      [series]="chartOptions.series!"
      [chart]="chartOptions.chart!"
      [xaxis]="chartOptions.xaxis!"
      [stroke]="chartOptions.stroke!"
      [labels]="chartOptions.labels!"
      [title]="chartOptions.title!"
      [grid]="chartOptions.grid!"
      [yaxis]="chartOptions.yaxis!"
      [legend]="chartOptions.legend!"
      [tooltip]="chartOptions.tooltip!"
    ></apx-chart>
  </div>
  
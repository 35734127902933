import { Injectable, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { PopupRequest, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogBoxComponent } from '../../features/basic/components/login/dialog-box/dialog-box.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey: string = 'msal.idToken';
  private interactionInProgress = new BehaviorSubject<boolean>(false);
  private isLoggingIn = false;  // Flag to prevent re-entry into the login function
  userAccount: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    // Subscribe to interaction status to monitor login/logout interactions
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status !== InteractionStatus.None),
        debounceTime(200) // Small delay to ensure status is properly updated
      )
      .subscribe((status: InteractionStatus) => {
        this.interactionInProgress.next(status === InteractionStatus.Login);
      });
  }

  get token(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  set token(value: string | null) {
    if (value) {
      localStorage.setItem(this.tokenKey, value);
    } else {
      localStorage.removeItem(this.tokenKey);
    }
  }

  clear(): void {
    localStorage.clear();
  }

  loginFn(): void {
    if (this.interactionInProgress.getValue() || this.isLoggingIn) {
      console.warn('Login interaction already in progress');
      return;
    }

    this.isLoggingIn = true;
    const authRequest = this.msalGuardConfig.authRequest ? { ...this.msalGuardConfig.authRequest } : {};

    this.msalService.loginPopup(authRequest as PopupRequest).subscribe({
      next: (response: AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(response.account);
        this.token = response.idToken;  // Store the token securely

        // Call validateAccess after successful login
        this.validateUserAccess(response.account.username);
      },
      error: (error) => {
        console.error('Login failed:', error);
        this.isLoggingIn = false;  // Reset the flag even if login fails
      },
    });

    this.interactionInProgress.next(true);
  }

  validateUserAccess(username: string) {
    this.apiService.validateAccess(username).subscribe(response => {
      this.isLoggingIn = false;  // Reset the flag after validation
      if (response.hasAccess === true) {
        localStorage.setItem('role', 'Admin');
        localStorage.setItem('redirectUrlAfterLogin', '/home/mps-overview');
        this.router.navigate(['/home/mps-overview']);
      } else if (response.hasAccess === false) {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
          data: {
            username: this.msalService.instance.getActiveAccount().name,
            email: this.msalService.instance.getActiveAccount().username
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.router.navigate(['/login']);
        });
      }
    }, error => {
      console.error('Access validation failed:', error);
      this.isLoggingIn = false;  // Reset the flag in case of error
    });
  }
}
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexPlotOptions, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { MixedChartData } from './mixed-chart-data';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { AppService } from 'src/app/services/app/app.service';

export type ChartMixedOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis[];
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  annotations: ApexAnnotations;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-chart-mixed',
  templateUrl: './chart-mixed.component.html',
  styleUrls: ['./chart-mixed.component.scss']
})
export class ChartMixedComponent implements OnInit, OnChanges {
  public chartOptions!: Partial<ChartMixedOptions>;
  @Input() chartData!: MixedChartData;

  constructor(private appService: AppService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['chartData'].firstChange) {
      this.chartOptions.series = changes['chartData'].currentValue['series'];
      this.chartOptions.yaxis = changes['chartData'].currentValue['yaxisData'];
    }
  }

  ngOnInit(): void {

    this.chartOptions = {
      series: this.chartData.series,
      chart: {
      type: this.chartData.chartType,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    legend: {
      show: true, // Set legend visibility here
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        // colors: '#A7A7DC',
        colors: '#AFAEBA',
        useSeriesColors: false
      }
    },
    stroke: this.chartData.stroke,
    title: {
      text: this.chartData.Title,
      style: {
        color: "#fff"
      }
    },
    grid: {
      strokeDashArray: 4,
      borderColor: '#414257',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      theme: 'dark'
    },
    dataLabels: this.chartData.dataLabels,
    xaxis: this.chartData.xaxisData,
    yaxis: this.chartData.yaxisData,
    annotations: {
      yaxis: this.chartData.annotation
    }
    };
  }
}

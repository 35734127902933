<div class="ey-h-100 ey-pr">
    <div>
        <app-header [title]="selectedTile?.title"></app-header>
    </div>
    <div class="main-body">
        <div class="tile-main ey-h-100">
            <div class="tile ey-pr" *ngFor="let tile of tilesData" (click)="onTileSelect(tile)">
                <div class="tile-img" [class]="tile.class">
                </div>
                <div class="tile-text">{{tile.label}}</div>
            </div>
        </div>
    </div>
</div>
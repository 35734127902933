import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @ViewChild("menu", { static: true }) menu: MatMenu;
  @Input() items: any[] = [];

  constructor(public router: Router) { }

  ngOnInit() { }

}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ScreenRotationService } from 'src/app/services/screen-rotation.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    loggedInUser: string;
    userRoles = ['Admin'];
    //userRoles = ['Admin', 'VP of Operations', 'Plant Operator'];
    defaultuserRole: any;

    @Input() title: string;

    constructor(
        private authService: MsalService,
        private router: Router) { }

    ngOnInit(): void {
        this.defaultuserRole = localStorage.getItem('role') ?? this.userRoles[0];
        this.loggedInUser = this.authService.instance.getActiveAccount().name.split('.').join(' ');
    }

    logout() {
        sessionStorage.removeItem('tsqExpressions');
        localStorage.removeItem('role');
        this.router.navigate(["/logout"]);
    }
    userDefinedTabs(role = 'VP of Operations') {
        localStorage.setItem('role', role);
        location.reload()

    }

    gotoMainPage(): void {
        this.router.navigate(['/home/mps-overview']);
    }


}

<mat-sidenav-container class="ey-side-nav" autosize>
    <mat-sidenav color="primary" mode="side" opened (mouseenter)="openSidebar=true" (mouseleave)="openSidebar=false">
        <mat-nav-list>
            <div class="sidebar" [class.sidebar-close]="!openSidebar">
                <div class="nav-links" id="nav-links">
                    <hr class="separator-line">
                    <div class="menu-list" *ngFor="let item of menuList;let i = index " #itemEl
                        routerLinkActive="active">
                        <hr class="separator-line" *ngIf="item.label == 'ASTERISK'">
                        <div *ngIf="item.children.length == 0" class="dropdown-title">
                            <a [routerLink]="[item.routerLink]">
                                <!-- <mat-icon class="material-symbols-outlined menu-icon">{{item.icon}}</mat-icon> -->
                                <mat-icon class="menu-icon material-symbols-outlined">{{getMatIcon(i)}}</mat-icon>
                                <span *ngIf="openSidebar" class="link_name">
                                    {{item.label}}
                                </span>
                            </a>
                        </div>
                        <div *ngIf="item.children.length > 0" class="dropdown-title" (click)="showSubmenu(itemEl)">
                            <a>
                                <!-- <mat-icon class="menu-icon">{{item.icon}}</mat-icon> -->
                                <mat-icon class="menu-icon material-symbols-outlined">{{getMatIcon(i)}}</mat-icon>
                                <!-- <img style="width: 25px;" src="../../../../assets/images/dashboard/{{item.icon}}.svg"> -->
                                <span *ngIf="openSidebar" class="link_name">{{item.label}}</span>
                            </a>
                            <mat-icon *ngIf="openSidebar" class="dropdown-arrow">keyboard_arrow_down</mat-icon>
                        </div>
                        <div class="sub-menu" [class.blank]="item.children.length == 0">
                            <div><a class="link_name">{{item.label}}</a></div>
                            <div *ngFor="let item_sub of item.children" routerLinkActive="active" #leafEl>
                                <div *ngIf="item_sub.children.length == 0">
                                    <a class="item_sub_label"
                                        [routerLink]="[item_sub.routerLink]">{{item_sub.label}}</a>
                                </div>
                                <div *ngIf="item_sub.children.length > 0" class="sub-dropdown-title"
                                    (click)="showLeafMenu(leafEl)">
                                    <div class="sub-dropdown-title-content">
                                        <a *ngIf="openSidebar" class="sub-link-name">{{item_sub.label}}</a>
                                        <mat-icon *ngIf="openSidebar"
                                            class="sub-dropdown-arrow">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                                <div class="leaf-sub-menu" [class.blank]="item_sub.children.length == 0">
                                    <div class="leaf-sub-menu-content" *ngFor="let leaf_menu of item_sub.children"
                                        routerLinkActive="active" #leafSubEl>
                                        <div *ngIf="leaf_menu.children.length == 0">
                                            <a class="leaf-link-name"
                                                [routerLink]="[leaf_menu.routerLink]">{{leaf_menu.label}}</a>
                                        </div>
                                        <div *ngIf="leaf_menu.children.length > 0" class="sub-dropdown-title"
                                            (click)="showLeafSubMenu(leafSubEl)">
                                            <div class="sub-dropdown-title-content">
                                                <a *ngIf="openSidebar" class="leaf-name">{{leaf_menu.label}}</a>
                                                <mat-icon *ngIf="openSidebar"
                                                    class="leaf-dropdown-arrow">keyboard_arrow_down</mat-icon>
                                            </div>
                                        </div>
                                        <div class="leaf-menu" [class.blank]="leaf_menu.children.length == 0">
                                            <div class="leaf-menu-content"
                                                *ngFor="let leaf_sub_menu of leaf_menu.children"
                                                routerLinkActive="active">
                                                <div *ngIf="leaf_sub_menu.children.length == 0">
                                                    <a class="leaf-sub-menu-name"
                                                        [routerLink]="[leaf_sub_menu.routerLink]">{{leaf_sub_menu.label}}</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="separator-line">
                </div>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="side-content" [style.margin-left.px]="isHovered ? 10 : 60">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<mat-toolbar class="header" color="primary">
    <!-- Preload and optimize the logo image -->
    <link rel="preload" href="assets/dist/images/EY_Logo_Beam_RGB.png" as="image">
    <div class="ey-vc" (click)="gotoMainPage()">
        <img src="assets/dist/images/EY_Logo_Beam_RGB.png" alt="EY Logo" class="ey-logo" />
        <p class="heading-class title-content">
            {{title ?? 'Supply Chain & Ops Platform'}}
        </p>
    </div>
    <div class="toolbar-spacer"></div>
    <ng-content></ng-content>
    <div class="logoutDiv">
        <div class="dropdown header-menu-tab">
            <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                <!-- <i class="fa fa-user mr-2" aria-hidden="true"></i> -->
                <mat-icon class="person-icon">person</mat-icon>{{ loggedInUser }}
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let role of userRoles">
                    <a (click)="userDefinedTabs(role)">
                        <!-- <i class="fa fa-id-card mr-2" aria-hidden="true"></i> -->
                        <mat-icon class="admin-icon">credit_card</mat-icon>{{ role }}
                    </a>
                </li>
                <li>
                    <a class="disabledLink">
                        <!-- <i class="fa fa-cog mr-2" aria-hidden="true"></i> -->
                        <mat-icon class="settings-icon">settings</mat-icon>Settings
                    </a>
                </li>
                <li>
                    <a (click)="logout()">
                        <!-- <i class="fa fa-power-off mr-2" aria-hidden="true"></i> -->
                        <mat-icon class="logout-icon">logout</mat-icon>Logout</a>
                </li>
            </ul>
        </div>
    </div>
</mat-toolbar>
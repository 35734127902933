import { formatNumber } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'customnumber'
  })

export class CustomNumberPipe implements PipeTransform {


    transform(value: number | string) {
        
        if(!Number.isInteger(+value) || !+value) {
            return value;
        }

        return formatNumber(+value, 'en');
    }

}
import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'prod-downtime',
  templateUrl: './prod-downtime.component.html',
  styleUrls: ['./prod-downtime.component.scss']
})
export class ProdDowntimeComponent implements OnInit {
  digitalTwinContent: SafeHtml;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadDigitalTwinContent();
  }

  loadDigitalTwinContent(): void {
    const digitalTwinUrl = 'https://explorer.digitaltwins.azure.net/3DScenes/?sceneId=30ba3e63deb14d5f8992a00787bcffbc&selectedElementIds=1dfdddd4c20d434e8cf4620fcda8631a&selectedLayerIds=scene-layer-dropdown-unlayered-behaviors&mode=Viewer&adtUrl=https%3A%2F%2Feuwdsrg03radt01.api.weu.digitaltwins.azure.net&adtResourceId=%2Fsubscriptions%2F21be855a-14e1-4de5-b670-01ca21a7a46b%2FresourceGroups%2FEUWDSRG03RRSG01%2Fproviders%2FMicrosoft.DigitalTwins%2FdigitalTwinsInstances%2FEUWDSRG03RADT01&storageUrl=https%3A%2F%2Feuwdsrg03rsta03.blob.core.windows.net%2F3dscenes&tid=5b973f99-77df-4beb-b27d-aa0c70b8482c';

    this.http.get(digitalTwinUrl, { responseType: 'text' }).subscribe(
      (response: string) => {
        this.digitalTwinContent = this.sanitizer.bypassSecurityTrustHtml(response);
      },
      (error) => {
        console.error('Error loading Digital Twin content:', error);
      }
    );
  }
}
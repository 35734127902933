import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highchart
  @Input() seriesData;
  updateFlag = false;
  @Input() categories: string[] = [];
  @Input() pointWidth;
  @Input() plotLinesData;
  @Input() yAxisCategories?: string[];
  @Input() xAxiscategories?: []
  @Input() yAxisTitle?: string;
  @Input() xAxisTitle?: string;
  @Input() yAxisMin?: number;
  @Input() yAxisMax?: number;
  @Input() yAxisTickAmount?: number;
  @Input() xAxisMin?: number;
  @Input() xAxisMax?: number;
  @Input() xAxisTickAmount?: number;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() gridLineColor: string = '';
  @Input() chartHeight: number = 250;
  @Input() barColor: string = '';
  @Input() legendEnabled: boolean = false;
  @Input() xAxisTitleFontSize: string; // Default font size for x-axis title
  @Input() yAxisTitleFontSize: string;
  @Input() colors: ['#DA5BB3', '#8965CC', '#1275C1', '#1F893F', '#D07200', '#DA96C3', '#D07200', '#361C75', '#1D8583'];
  @Input() areaOptions: any = {  // Use any or a more generic type if specific type is not available
    stacking: "normal",
    lineColor: "#B61FB0",
    lineWidth: 1,
    marker: {
      enabled: false
    },
    color: "#2b193f"
  };

  yAxisData = [];

  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: '#131329',
      type: 'area',
      height: this.chartHeight,
      // width: 400,
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    responsive: {
      rules: [{
        condition: {
          minWidth: 500,
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          },

        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    xAxis: {
      categories: this.categories,
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      labels: {
        style: {
          color: '#AFAEBA'
        }
      },
      min: this.xAxisMin,
      max: this.xAxisMax,
      tickAmount: this.xAxisTickAmount,
    },
    yAxis: {
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      plotLines: [],      // Handling from plotLinesData object
      labels: {
        format: this.yAxisLabelFormat,
        style: {
          color: '#AFAEBA'
        },
      },
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      min: this.yAxisMin,
      max: this.yAxisMax,
      tickAmount: this.yAxisTickAmount,
    },
    // tooltip: {
    //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //   pointFormat: '<tr><td style="color:{series.color};padding:0">{point.key}</td>' +
    //     '<td style="padding:0"><b>{point.y}</b></td></tr>',
    //   footerFormat: '</table>',
    //   shared: true,
    //   useHTML: true
    // },
    plotOptions: {
      area: this.areaOptions,
      series: {
        dataLabels: {
          enabled: true,
          inside: true,
          verticalAlign: 'bottom',
          y: 20,
          formatter: function () {
            return this.y + '%';
          },
          style: {
            color: '#f9f9fa',
            fontWeight: 'normal'
          },
        },
      }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      enabled: this.legendEnabled,
      itemStyle: {
        color: '#8F8FAC',
        borderRadius: 5,
      },
      symbolRadius: 0,
    },
    series: [{
      type: 'area',
      data: []
    }]
  }

  constructor() { }
  ngOnInit(): void {
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.chartOptions.chart = {
      ...this.chartOptions.chart,
      height: this.chartHeight
    };

    this.chartOptions.legend = {
      ...this.chartOptions.legend,


      enabled: this.legendEnabled,
      itemStyle: {
        color: '#8F8FAC',
        borderRadius: 5,
      },
      symbolRadius: 0,
    };


    this.chartOptions.plotOptions = {
      ...this.chartOptions.plotOptions,
      area: this.areaOptions,
    }

    this.chartOptions.yAxis = {
      ...this.chartOptions.yAxis,

      min: this.yAxisMin,
      max: this.yAxisMax,
      tickAmount: this.yAxisTickAmount,
      labels: {
        format: this.yAxisLabelFormat,
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      },
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      title: {
        text: this.yAxisTitle,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
    };
    this.chartOptions.xAxis = {
      ...this.chartOptions.xAxis,
      min: this.xAxisMin,
      max: this.xAxisMax,
      tickAmount: this.xAxisTickAmount,
      categories: this.categories,
      title: {
        text: this.xAxisTitle,
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
    };

    const seriesData = changes.seriesData.currentValue;
    if (seriesData && seriesData.length > 0) {
      seriesData.forEach((el, i) => {
        this.chartOptions.series[i] = {
          type: 'area',
          name: el.name,
          data: el.series
        }
      });
      if (seriesData[0].series && seriesData[0].series.length > 0) {
        seriesData[0].series.map((obj) => {
          this.categories.push(obj.category);
        })
      }
      if (this.seriesData && this.seriesData.length > 0) {
        this.chartOptions.colors = this.colors; // Dynamically set colors

        this.seriesData.forEach((el, i) => {
          const seriesColor = el.color || this.colors[i]; // Use provided color or default from colors array
          this.chartOptions.series[i] = {
            type: 'area',
            name: el.name,
            data: el.series,
            // color: '#B61FB01F' // Set color for the series
          };
        });

      }
      seriesData.forEach(element => {
        (<Highcharts.XAxisOptions>this.chartOptions.xAxis).title = {
          text: element.xAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        };
        (<Highcharts.YAxisOptions>this.chartOptions.yAxis).title = {
          text: element.yAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        };
        this.chartOptions.legend = { // Update the legend property directly
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          enabled: this.legendEnabled,
          itemStyle: { color: '#8F8FAC' },
          title: { text: element.yAxisTitle } // Set the title based on yAxisTitle
        };

      })
      if (this.plotLinesData) {
        var addPlotLines = [];
        changes.plotLinesData.currentValue.forEach((el) => {
          addPlotLines.push({
            "color": el.color,
            "width": "1",
            "value": el.value,
            "zIndex": "5"
          });
          // this.chartOptions.yAxis = {
          //     ...this.chartOptions.yAxis,
          //     min: this.yAxisMin,
          //     max: this.yAxisMax,
          //     tickAmount: this.yAxisTickAmount
          // };
          (<Highcharts.YAxisOptions>this.chartOptions.yAxis).plotLines = addPlotLines;
        })
      }
      this.updateFlag = true;
    }
  }
}

<div class="dialog-container">
    <div class="header-div">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <span class="title">{{data.title}}</span>
    </div>
    <div *ngIf="displayProdDowntime">
        <div mat-dialog-content>
            <div class="alert-summary">
                <table class="alert-summary-table">
                    <thead class="alert-thead">
                        <tr class="table-thead">
                            <th class="thead-title">Factors Impacting OTIF</th>
                            <th class="thead-title">% Impact on OTIF</th>
                            <th class="thead-title">Customer Impacted</th>
                            <th class="thead-title">Revenue Impact</th>
                            <th class="thead-title">Asset Links</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of prodDowntimeData">
                            <td>{{data.factorsImpactingOTIF}}</td>
                            <td>{{data.percImpactOnOTIF}}</td>
                            <td>
                                <!-- <mat-progress-bar class="custom-progress" mode="determinate"
                                    value="3"></mat-progress-bar> -->
                                {{data.customerImpacted}}
                            </td>
                            <td>{{data.revenueImpact}}</td>
                            <td><a class="assetLink" (click)="openAssetLink(data.assetLink)">{{data.assetLink}}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <table mat-table class="modeldetail mat-elevation-z8" [dataSource]="dataSource">

        <ng-container matColumnDef="Values" sticky>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let data">{{data.Values}}</td>
        </ng-container>

        <ng-container matColumnDef="Machine Learning">
            <th mat-header-cell *matHeaderCellDef> Machine Learning </th>
            <td mat-cell *matCellDef="let data">{{data['Machine Learning']}}</td>
        </ng-container>

        <ng-container matColumnDef="Timeseries">
            <th mat-header-cell *matHeaderCellDef> Timeseries </th>
            <td mat-cell *matCellDef="let data">{{data['Timeseries']}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
</div>
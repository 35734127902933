import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ModelDetailService } from 'src/app/services/sku/model-detail.service';

@Component({
  selector: 'app-forecast-models',
  templateUrl: './forecast-models.component.html',
  styleUrls: ['./forecast-models.component.scss']
})
export class ForecastModelsComponent {

  displayedColumns = ['Values', 'Machine Learning', 'Timeseries'];
 

  dataSource = new MatTableDataSource<any>();


  constructor(private modelDetails: ModelDetailService) {
    

    this.dataSource.data = this.modelDetails.data;

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '@env';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { CustomHttpInterceptor } from './http.interceptor';
import { getCoreAPIBaseUrl, getDeviceManagementUrl } from '../constants/api.urls';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.clientId,
            authority: "https://login.microsoftonline.com/" + environment.msalConfig.tenantId + "/",
            redirectUri: environment.msalConfig.redirectUri,
            postLogoutRedirectUri: environment.msalConfig.redirectUri
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
    protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(getCoreAPIBaseUrl(), ['user.read']);
    protectedResourceMap.set(getDeviceManagementUrl(), ['user.read']);
    protectedResourceMap.set('https://atlas.microsoft.com/map/tile', ['user.read']);
    protectedResourceMap.set('https://usediac01hazf01.azurewebsites.net/', ['user.read']);

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { interactionType: InteractionType.Popup };
}



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        MsalModule
    ],
    exports: [
        HttpClientModule,
        MsalModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ]
})

export class CoreModule { }
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import { geoJson } from '../../models/geoJson';
import { oceanJson } from '../../models/oceanJson';
import { MapService } from 'src/app/services/map.service';
import { Router } from '@angular/router';
import { icon, Icon } from 'leaflet';

@Component({
  selector: 'dms-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges, OnDestroy {
  @Input() setHeight;
  @Input() setZoom;
  @Input() plantDetails: any = [];
  @Input() updatedDetails = false;
  @Input() dashboard!: string;
  private map: L.Map | any;
  mapDiv!: any;
  private centroid: L.LatLngExpression = [42.3601, 15.0589];
  statesData = geoJson;
  oceanData = oceanJson;
  markers: L.Layer[] = [];

  redIcon: Icon = icon({
    iconUrl: 'assets/images/red_dot.png',
    iconSize: [16, 16],
  });
  amberIcon: Icon = icon({
    iconUrl: 'assets/images/amber_dot.png',
    iconSize: [16, 16],
  });
  greenIcon: Icon = icon({
    iconUrl: 'assets/images/green_dot.png',
    iconSize: [16, 16],
  });
  yellowIcon: Icon = icon({
    iconUrl: 'assets/images/yellow_dot.png',
    iconSize: [16, 16],
  });

  constructor(private router: Router, private mapService: MapService) { }

  ngOnChanges() {
    if (this.plantDetails && this.updatedDetails) {
      this.map.off();
      this.map.remove();
      this.initMap();
    } else if (this.plantDetails) {
      setTimeout(() => {
        this.initMap();
      }, 300)

    }
  }

  removeMarker() {
    if (this.markers) {
      this.map.removeLayer(this.markers); // remove
    }
  }

  style() {
    return {
      fillColor: '#5E5E81',
      weight: 2,
      opacity: 1,
      color: '#61616240',
      dashArray: '0',
      fillOpacity: 0.7,
    };
  }

  oceanStyle() {
    return {
      weight: 2,
      opacity: 1,
      color: '#61616240',
      fillColor: '#131329',
      dashArray: '0',
      fillOpacity: 1,
    };
  }

  oceanStyleDefault() {
    return {
      weight: 2,
      opacity: 1,
      color: '#61616240',
      fillColor: '#131329',
      dashArray: '0',
      fillOpacity: 1,
    };
  }


  private initMap(): void {
    this.map = L.map('map', {
      center: this.centroid,
      zoom: this.setZoom,
      maxZoom: 18,
      minZoom: 1,
    });
    this.mapDiv = document.getElementById('map');

    const tiles = L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 18,
        minZoom: 4,
        noWrap: true,
        attributionControl: false,
        className: 'map-tiles',
      }
    );

    const resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });

    resizeObserver.observe(this.mapDiv);

    L.geoJson(this.statesData as any, { style: this.style }).addTo(this.map);
    if (this.dashboard === 'launchpad') {
      L.geoJson(this.oceanData as any, { style: this.oceanStyle }).addTo(
        this.map
      );
    }
    if (this.dashboard !== 'launchpad') {
      L.geoJson(this.oceanData as any, { style: this.oceanStyleDefault }).addTo(
        this.map
      );
    }

    this.plantDetails.forEach((ele: any) => {
      const icon = this.getIcon(ele);
      this.addMarker(ele, icon);
    });

  }


  getPlantPopupDetails(plantDetail: any, icon: any): any {
    if (this.dashboard === 'launchpad') {
      const newMarker = L.marker(
        [plantDetail.latitude, plantDetail.longitude],
        { icon: icon }
      );
      this.markers.push(newMarker);
      const customPopup =
        "<div class='row tooltip-header'>" +
        plantDetail.kpiType +
        '</div>' +
        "<div class='row mt-3 property'>" +
        "<div class='col-md-8'>" + plantDetail.plantName + "</div><div class='col-md-4' style='text-align:right;'>" +
        plantDetail.value + '%' +
        '</div></div>' +
        '</div>' +
        '</div>';
      const customOptions: L.PopupOptions = {
        className: 'customPopup',
      };
      newMarker.addTo(this.map).bindPopup(customPopup, customOptions);
      return;
    }
  }

  addMarker(plantDetail: any, icon: any) {
    this.getPlantPopupDetails(
      plantDetail,
      icon
    );
  }
  getMarkerColor(ele: any) {
    const icon =
      ele.demandNos > 0
        ? this.redIcon
        : ele.plantType === 'PL'
          ? this.redIcon
          : this.yellowIcon;
    return icon;
  }
  getPlantColor(element: string) {
    switch (element) {
      case 'amber':
        return this.amberIcon;
      case 'red':
        return this.redIcon;
      case 'yellow':
        return this.yellowIcon;
      case 'green':
        return this.greenIcon;
      default:
        return this.redIcon;
    }
  }
  getWarehouseColor(element: any) {
    if (element.plantType === 'DC' && element.demandNos > 0)
      return this.yellowIcon;
    else if (element.plantType === 'DC' && element.demandNos === 0)
      return this.yellowIcon;
    else return this.redIcon;
  }
  getIcon(element: any) {
    switch (this.dashboard) {
      case 'launchpad':
        return this.getPlantColor(element.color);
      default:
        return this.getMarkerColor(element.color);
    }
  }

  ngOnDestroy(): void {
    this.map.off();
    this.map.remove();
  }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'graph-node',
    templateUrl: './graph-node.component.html',
    styleUrls: ['./graph-node.component.scss']
})
export class GraphNodeComponent {
    @Input() name: string;
    @Input() color!: string;
    outerCircleVisible = false;
    group: string;

    toggleOuterCircle() {
        this.outerCircleVisible = !this.outerCircleVisible;
    }
}

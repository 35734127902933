import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent implements OnInit {
  form: FormGroup;
  constructor(private dialogRef1: MatDialogRef<RequestAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { username: string, email: string },
    private fb: FormBuilder,
    private apiService: ApiService,
    private _snackBar: MatSnackBar
  ) {
    if (!this.data) {
      this.data = { username: '', email: '' };
    }
    this.form = this.fb.group({
      email: [{ value: this.data.email, disabled: true }, [Validators.required, Validators.email]],
      gpn: ['', Validators.required],
      businessJustification: ['', [Validators.required, Validators.maxLength(200)]]
    });
  }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef1.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['custom-snackbar'],
      duration: 8000
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    if (control.hasError('required')) {
      return 'This field is required';
    }
    if (controlName === 'email' && control.hasError('email')) {
      return 'Not a valid email';
    }
    if (controlName === 'businessJustification' && control.hasError('maxlength')) {
      return `Maximum 200 characters allowed`;
    }
    return '';
  }

  onSubmit() {
    const payload = {
      email: this.data.email,
      gpn: this.form.value.gpn,
      businessJustification: this.form.value.businessJustification
    };
    this.apiService.requestAccess(payload).subscribe(response => {
      if (response) {
        this.openSnackBar(response.businessJustification, 'CLOSE');
      }
    },
      (error) => {
        this.openSnackBar(`'An error occurred:' ${error}`, 'CLOSE');
      });
    this.dialogRef1.close();
  }

}

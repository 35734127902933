import { Component, OnInit, AfterViewInit, ElementRef, Input, SimpleChanges, ViewChild, OnChanges, ApplicationRef, ComponentFactoryResolver, Injector, HostListener } from '@angular/core';
import ForceGraph3D from '3d-force-graph';
import * as THREE from 'three';
import * as d3 from 'd3-force-3d';
import SpriteText from 'three-spritetext';
import { GraphNodeComponent } from './graph-node/graph-node.component';
import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';

interface NodeObject {
  id: string;
  name?: string;
  group?: string;
  color?: string;
}

interface LinkObject {
  source: NodeObject;
  target: NodeObject;
  label?: string;
}

@Component({
  selector: 'three-d-force-graph',
  templateUrl: './three-d-force-graph.component.html',
  styleUrls: ['./three-d-force-graph.component.scss']
})
export class ThreeDForceGraphComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() graphData: any;
  @Input() width: any;
  @Input() height: any;
  @ViewChild('graphContainer', { static: true }) graphContainer: ElementRef;
  private Graph: any;
  color: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) { }

  ngOnInit(): void {
    this.Graph = ForceGraph3D({
      extraRenderers: [new CSS3DRenderer() as CSS3DRenderer & THREE.Renderer]
    })(this.graphContainer.nativeElement)
      .backgroundColor('rgba(0,0,0,0)')
      .width(window.innerWidth)
      //.width(this.width)
      .height(400)
      .showNavInfo(true)
      .nodeAutoColorBy('group')
      //.nodeRelSize(10)
      .linkWidth(1)
      .linkDirectionalParticles(4)
      .linkDirectionalArrowLength(10) // Length of the arrow
      .linkDirectionalArrowRelPos(1) // Position of the arrow (1 means at the end of the link)
      .d3Force('link', d3.forceLink().distance(100)) // Adjust link distance
      .linkThreeObjectExtend(true) // Allow extending link objects
      .linkThreeObject((link: LinkObject) => {
        const sprite = new SpriteText(link.label);
        sprite.color = 'lightgrey';
        sprite.textHeight = 4;
        return sprite;
      })
      .linkPositionUpdate((sprite, { start, end }) => {
        const middlePos = new THREE.Vector3(
          start.x + (end.x - start.x) / 2,
          start.y + (end.y - start.y) / 2,
          start.z + (end.z - start.z) / 2
        );
        sprite.position.copy(middlePos);
      })
      .nodeThreeObject((node: NodeObject) => {
        // Create an Angular component dynamically
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GraphNodeComponent);
        const componentRef = componentFactory.create(this.injector);
        componentRef.instance.name = node.name;
        switch (node.group) {
          case 'Customer':
            this.color = '#DC0083';
            break;
          case 'Location':
            this.color = '#B60071';
            break;
          case 'Lots':
            this.color = '#4535C1';
            break;
          case 'Manufacturer':
            this.color = '#468585';
            break;
          case 'ManufacturerLot':
            this.color = '#E76F51';
            break;
          case 'Order':
            this.color = '#DA7297';
            break;
          case 'Pallet':
            this.color = '#EE4E4E';
            break;
          case 'PalletType':
            this.color = '#BC5A94';
            break;
          case 'RawMaterial':
            this.color = '#006989';
            break;
          case 'Retailer':
            this.color = '#987070';
            break;
          case 'Sku':
            this.color = '#8E3E63';
            break;
          case 'Vendor':
            this.color = '#006769';
            break;
          case 'VendorLot':
            this.color = '#A91D3A';
            break;
          case 'Warehouse':
            this.color = '#FF6500';
            break;
          default:
            this.color = '#E1AFD1';
            break;
        }
        componentRef.instance.color = this.color;
        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
        const object = new CSS3DObject(domElem);
        return object;
      })
      .nodeThreeObjectExtend(true) // Allow extending node objects
  }

  ngAfterViewInit(): void {
    this.Graph.graphData(this.graphData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.graphData && this.Graph) {
      this.Graph.graphData(this.graphData);
    }
  }

  exportAsImage(): void {
    const renderer = this.Graph.renderer();
    const scene = this.Graph.scene();
    const camera = this.Graph.camera();

    renderer.render(scene, camera);

    renderer.domElement.toBlob(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = '3d-force-graph.jpg';
      link.click();
    }, 'image/jpeg');
  }

}
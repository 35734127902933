import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss']
})
export class GroupedBarChartComponent implements OnInit {
  @Input() getGroupedBarChartData: any[] = [];
  @Input() view: any[] = [];
  @Input() colorScheme;
  @Input() showXAxis;
  @Input() showYAxis;
  @Input() gradient;
  @Input() showLegend;
  @Input() showXAxisLabel;
  @Input() xAxisLabel;
  @Input() showYAxisLabel;
  @Input() yAxisLabel;
  @Input() legendTitle;
  @Input() legendPosition;

  constructor() { }

  ngOnInit(): void {
  }

}

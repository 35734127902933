import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-close-button-snackbar',
  template: `
    <div class="toast-message">
    	<span [innerHTML]="data.message"></span>
    	<button mat-icon-button (click)="onClose()">
    		<mat-icon aria-label="Close">close</mat-icon>
    	</button>
    </div>`,
  styles: [`
    .toast-message {
        display: flex;
        align-items: center;
        background-color: #25254C;
        color: #D7D7DC;
        padding: 12px;
        font-size: 14px;
        border-radius: 4px;
    }
    button {
        margin-left: auto;
        background: none;
        color: white;
    }`]
})
export class CloseButtonSnackbarComponent {
  @Output() closed = new EventEmitter<void>();

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  onClose(): void {
    this.closed.emit();
  }
}

import { NgModule } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";

@NgModule({
    imports: [MatIconModule]
})
export class IconModule {
    private path: string = "./../../../../assets/images";
    constructor(
        private domSanitizer: DomSanitizer,
        public matIconRegistry: MatIconRegistry) {
        this.matIconRegistry
            .addSvgIcon("home", this.setPath(`${this.path}/home.svg`))
            .addSvgIcon("transfer", this.setPath(`${this.path}/transfer.svg`))
            .addSvgIcon("user", this.setPath(`${this.path}/user.svg`))
            .addSvgIcon("economic-investment", this.setPath(`${this.path}/economic-investment.svg`))
            .addSvgIcon("credit-card", this.setPath(`${this.path}/credit-card.svg`))
            .addSvgIcon("loan", this.setPath(`${this.path}/loan.svg`))
            .addSvgIcon("service", this.setPath(`${this.path}/service.svg`))
            .addSvgIcon("econometrics", this.setPath(`${this.path}/econometrics.svg`))
    }
    private setPath(url: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);

@Component({
  selector: 'column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent implements OnInit, OnChanges {
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highchart
  @Input() seriesData;
  updateFlag = false;
  @Input() categories: string[] = [];
  @Input() pointWidth;
  @Input() plotLinesData;
  @Input() yAxisCategories?: string[];
  @Input() xAxiscategories?: []
  @Input() yAxisTitle?: string;
  @Input() xAxisTitle?: string;
  @Input() yAxisMin?: number;
  @Input() yAxisMax?: number;
  @Input() yAxisTickAmount?: number;
  @Input() xAxisTickAmount?: number;
  @Input() xAxisMin?: number;
  @Input() xAxisMax?: number;
  @Input() yAxisLabelFormat?: string = '{value}';
  @Input() gridLineColor: string = '';
  @Input() chartHeight: number = 250;
  @Input() barColor: string = '';
  @Input() legendEnabled: boolean = true;
  @Input() xAxisTitleFontSize: string; // Default font size for x-axis title
  @Input() yAxisTitleFontSize: string;
  @Input() areasplineOptions: Highcharts.SeriesAreasplineOptions = null;

  // @Input() chartType: string = 'column';
  @Input() chartType: 'column' | 'bar' | 'line' | 'areaspline' | 'area' | 'columnpyramid' = 'column';
  @Input() colors = ['#DA5BB3', '#8965CC', '#1275C1', '#1F893F', '#D07200', '#DA96C3', '#D07200', '#361C75', '#1D8583'];

  yAxisData = [];

  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: 'none',
      type: this.chartType,
      height: this.chartHeight,
      styledMode: false
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    subtitle: {
      text: '',
      style: {
        display: 'none'
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }]
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    xAxis: {
      categories: this.categories,
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      labels: {
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      },
      min: this.xAxisMin,
      max: this.xAxisMax,
      tickAmount: this.xAxisTickAmount,
    },
    yAxis: {
      title: {
        text: '',
        style: {
          color: '#C3C3CB',
          fontSize: '10px',
          fontFamily: 'EYInterstate-Regular'
        }
      },
      labels: {
        format: this.yAxisLabelFormat,
        style: {
          color: '#AFAEBA',
          //fontSize: '1em'
        }
      },
      gridLineColor: '#414257',
      gridLineDashStyle: 'Dash',
      min: this.yAxisMin,
      max: this.yAxisMax,
      tickAmount: this.yAxisTickAmount,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        color: this.barColor,
      },
      bar: {
        borderWidth: 0,
        color: this.barColor,
      },
      areaspline: this.areasplineOptions || {
        marker: {
          enabled: false // Disable markers for areaspline series
        }
      },
      columnpyramid: {
        borderWidth: 0
      },
      series: {
        animation: false,
        shadow: false,
        dataLabels: {
          enabled: false,
          inside: true,
          format: '{point.y}',
        },
      }
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      enabled: this.legendEnabled,
      itemStyle: {
        color: '#8F8FAC',
        borderRadius: 5,
      },
      symbolRadius: 0,
    },
    series: [{
      type: 'column',
      data: []
    }]
  }

  constructor() { }
  ngOnInit(): void {
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
      this.chartOptions.chart = {
        ...this.chartOptions.chart,
        height: this.chartHeight,
      };

      this.chartOptions.legend = {
        ...this.chartOptions.legend,
        align: 'right',

        enabled: this.legendEnabled,
        itemStyle: {
          color: '#8F8FAC',
          borderRadius: 5,
        },
        symbolRadius: 0,
      };


      this.chartOptions.plotOptions = {
        ...this.chartOptions.plotOptions,
        column: {
          borderWidth: 0,
          color: this.barColor,
        },
        series: {
          dataLabels: {
            enabled: false,
            inside: true,
            format: '{point.y}',
          },
        }
      }

      this.chartOptions.yAxis = {
        ...this.chartOptions.yAxis,

        min: this.yAxisMin,
        max: this.yAxisMax,
        tickAmount: this.yAxisTickAmount,
        labels: {
          format: this.yAxisLabelFormat,
          style: {
            color: '#AFAEBA',
            //fontSize: '1em'
          }
        },
        gridLineColor: '#414257',
        gridLineDashStyle: 'Dash',
        title: {
          text: this.yAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
      };
      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        min: this.xAxisMin,
        max: this.xAxisMax,
        tickAmount: this.xAxisTickAmount,
        categories: this.categories,
        title: {
          text: this.xAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        },
      };
    }, 2000);
  }

  ngOnChanges(changes: SimpleChanges) {
    const seriesData = changes.seriesData.currentValue;
    if (this.areasplineOptions) {
      this.chartOptions.plotOptions.areaspline = {
        ...this.chartOptions.plotOptions.areaspline,
        ...this.areasplineOptions
      };
    }
    if (seriesData && seriesData.length > 0) {
      seriesData.forEach((el, i) => {
        this.chartOptions.series[i] = {
          type: 'column',
          name: el.name,
          data: el.series
        }
      });
      if (seriesData[0].series && seriesData[0].series.length > 0) {
        seriesData[0].series.map((obj) => {
          this.categories.push(obj.category);
        })
      }
      if (this.seriesData && this.seriesData.length > 0) {
        this.chartOptions.colors = this.colors; // Dynamically set colors

        this.seriesData.forEach((el, i) => {
          const seriesColor = el.color || this.colors[i]; // Use provided color or default from colors array
          this.chartOptions.series[i] = {
            type: this.chartType,
            name: el.name,
            data: el.series,
            color: seriesColor // Set color for the series
          };
        });

      }
      seriesData.forEach(element => {
        (<Highcharts.XAxisOptions>this.chartOptions.xAxis).title = {
          text: element.xAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        };
        (<Highcharts.YAxisOptions>this.chartOptions.yAxis).title = {
          text: element.yAxisTitle,
          style: {
            color: '#C3C3CB',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        };
        this.chartOptions.legend = { // Update the legend property directly
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          enabled: this.legendEnabled,
          itemStyle: { color: '#8F8FAC' },
          // title: { text: element.yAxisTitle } // Set the title based on yAxisTitle
        };

      })
      if (this.plotLinesData) {
        var addPlotLines = [];
        changes.plotLinesData.currentValue.forEach((el) => {
          addPlotLines.push({
            "color": el.color,
            "width": "1",
            "value": el.value,
            "zIndex": "5"
          });
          (<Highcharts.YAxisOptions>this.chartOptions.yAxis).plotLines = addPlotLines;
        })
      }
      this.updateFlag = true;
    }
  }
}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-chart-horizontal',
  templateUrl: './bar-chart-horizontal.component.html',
  styleUrls: ['./bar-chart-horizontal.component.scss']
})
export class BarChartHorizontalComponent implements OnInit {
  @Input() getBarChartData;
  @Input() chartItem: string;
  @Input() view: any[] = [];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  @Input() showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = '';
  @Input() colorScheme: any;
  legendTitle: string = '';
  @Input() xAxisTickData;

  constructor() { }

  ngOnInit(): void {
    /*     if (this.chartItem == 'isco') {
          this.colorScheme = { domain: ['#A10A28', '#C7B42C', '#BF40BF', '#5D3FD3', '#5AA454'] };
          this.view = [360, 180];
        } else if (this.chartItem == 'otif') {
          this.colorScheme = { domain: ['#BF40BF'] };
          this.view = [360, 130];
        } else if (this.chartItem == 'oee') {
          this.colorScheme = { domain: ['#5D3FD3'] };
          this.view = [360, 130]
        } */

  }

  /* xAxisTickFormatting(val) {
    return '$' + val + 'M';
  } */

}

import { Component, Input, OnInit } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
  ApexFill,
  ApexStroke
} from "ng-apexcharts";

type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
  colors?: any[];
};

@Component({
  selector: 'app-radial-semi-circle-guage-chart',
  templateUrl: './radial-semi-circle-guage-chart.component.html',
  styleUrls: ['./radial-semi-circle-guage-chart.component.scss']
})

export class RadialSemiCircleGuageChartComponent implements OnInit {

  public chartOptions!: Partial<ChartOptions>;

  ngOnInit(): void {
    this.chartOptions = {
      series: [76],
      chart: {
        height: 200,
        type: "radialBar",
      },
      colors: ["#20E647"],
      plotOptions: {
        radialBar: {
          startAngle: -90, // Adjusted start angle to -90 degrees
          endAngle: 90,    // Adjusted end angle to 90 degrees
          track: {
            background: '#333',
            startAngle: -90, // Adjusted start angle to -90 degrees
            endAngle: 90,    // Adjusted end angle to 90 degrees
            strokeWidth: '100%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "16px",
              offsetY: 1,
              show: true,
              color: '#fff'
            }
          },
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          gradientToColors: ["#87D4F9"],
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "butt",
      },
      labels: ["Progress"]
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import treemap from 'highcharts/modules/treemap';
import treegraph from 'highcharts/modules/treegraph';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from 'src/app/constants/api.urls';


treemap(Highcharts);
treegraph(Highcharts);

@Component({
  selector: 'app-tree-graph-box-layout',
  templateUrl: './tree-graph-box-layout.component.html',
  styleUrls: ['./tree-graph-box-layout.component.scss']
})
export class TreeGraphBoxLayoutComponent implements OnInit {
  @Input() chartOptions: Highcharts.Options;
  Highcharts: typeof Highcharts = Highcharts;

  constructor(private http: HttpClient) { }

  ngOnInit(): void { }

}
import { environment } from "@env";

export const API_SUB_URLS = {
    coreAPI: 'iotsphereapi/',
    securedApi: 'securedApi/',
    deviceManagementApi: 'devicemanagementapi/',
    iscoOverviewPageData: iscoOverviewPageData(),
    getMxDData: getMapAPIUrl(),
    mxdApi: 'mxdapi/',
    soeApi:'soe/',
}

export function getCoreAPIBaseUrl(): string {
    return environment.apiUrl + API_SUB_URLS.coreAPI;
}

function getSecuredAPIUrl(): string {
    return environment.apiUrl + API_SUB_URLS.coreAPI + API_SUB_URLS.securedApi;
}

export function getDeviceManagementUrl(): string {
    return environment.apiUrl + API_SUB_URLS.deviceManagementApi;
}

export function getDeviceLocationAPIUrl(): string {
    return 'https://euwdsrg02rafapp02.azurewebsites.net/api/';
}
export function iscoOverviewPageData() {
    return 'https://euwdsrg02rafapp07.azurewebsites.net/api/landingpage_dashboard1?code=2u7fp-lhIX7Mbb7MWK-Gle8GkGUOuXPOxVcYKTgOaG5fAzFuOKx2EA==';
}
export function iscoLandingPageChartData() {
    return 'https://euwdsrg02rafapp07.azurewebsites.net/api/landingpage_dashboard2?code=CNS3a45Zw_xtlWZRPMj0HfUNWd0-jXmyn1ZF5IxLnmQYAzFuvYgkvw==';
}

export function getSupplyChainDataUrl(): string {
    return 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Simulator/';
}

export function getMEIOUrl(): any {
    return 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/MEIO/';
}

export function getMapAPIUrl(): string {
    return '../../assets/json/GetAllPlantDetails.json';
}

export function getLogisticsCard(): string {
    return '../../assets/json/getLogisticList.json';
}

export function getPlannedDowntimePopup(): string {
    return '../../assets/json/planned-downtime.json';
}

export function getUnplannedDowntimePopup(): string {
    return '../../assets/json/unplanned-downtime.json';
}

export function getTraceabilityUrl(): any {
    return 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/mxdapi/Traceability/';
}

export function getValutBackendUrl(): any {
    return 'https://nexus-sco-dev.001.westeurope.containers.sbp.eyclienthub.com/';
}

export function getOutputTempUrl(): string {
    return '../../assets/json/output-temp.json';
}

export function getSOEApiUrl(): string {
    return getValutBackendUrl()+API_SUB_URLS.mxdApi+API_SUB_URLS.soeApi;
}

export const API_URLS = {
    keyVaultKeys: getSecuredAPIUrl() + 'keyVaultKeys',
    addAssetEndPoint: getSecuredAPIUrl() + "addOrUpdateAsset",
    retrieveAssetInfoEndPoint: getSecuredAPIUrl() + "fetchAssetParams",
    updateAssetInfoEndPoint: getSecuredAPIUrl() + "updateAssetInfo",
    deleteAssetEndPoint: getSecuredAPIUrl() + "deleteAsset",
    IoTStartCommandEndPoint: getSecuredAPIUrl() + "opcEdgeCommandServiceStartCall",
    IoTStopCommandEndPoint: getSecuredAPIUrl() + "opcEdgeCommandServiceStopCall",
    allCriticalEventsEndPoint: getSecuredAPIUrl() + "getPolarDelightEvents",
    onlineOfflineStatus: getSecuredAPIUrl() + "getAssetsStatus",
    fetchAllVehicles: getSecuredAPIUrl() + 'fetchAllVehicles',
    fetchDistinctAssets: getSecuredAPIUrl() + "fetchDistinctAssets",
    startCsvSimulatorEndPoint: getSecuredAPIUrl() + "startCsvSimulator",
    stopCsvSimulatorEndPoint: getSecuredAPIUrl() + "stopCsvSimulator",
    isSimulatorStatusEndPoint: getSecuredAPIUrl() + "isSimulatorsRunning",
    deviceDetailsEndPoint: getDeviceManagementUrl() + 'deviceDetailsEndPoint',
    getDeviceTwinData: getDeviceManagementUrl() + "GetDeviceTwinData",
    UpdateDeviceTwinData: getDeviceManagementUrl() + 'UpdateDeviceTwinData',
    directMethodCallEndPoint: getDeviceManagementUrl() + "CallDirectMethod",
    getConnectionHistoryForDevice: getDeviceManagementUrl() + 'GetConnectionHistoryForDevice',
    getAllDevicesLocaltion: getDeviceManagementUrl() + 'GetDeviceLatLongList',
    createEnrolment: getDeviceManagementUrl() + 'CreateEnrolment',
    bulkEnrolment: getDeviceManagementUrl() + 'BulkEnrolment',
    getDeviceTypes: getDeviceManagementUrl() + 'GetDeviceTypes',
    getExistingGroupEnrolmentIds: getDeviceManagementUrl() + 'GetExistingGroupEnrolmentIds',
    blockOrUnBlockIndividualDevice: getDeviceManagementUrl() + 'BlockOrUnBlockIndividualDevice',
    uploadSensorMetadata: getDeviceManagementUrl() + 'UploadSensorMetadata',
    getDeviceDetailList: getDeviceManagementUrl() + 'GetDeviceDetailList',
    blockGroupEnrolledDeviceWithCertificate: getDeviceManagementUrl() + 'BlockGroupEnrolledDeviceWithCertificate',
    decommissionDevice: getDeviceManagementUrl() + 'DecommissionDevice',
    createEmptyGroupEnrollmentWithCertificate: getDeviceManagementUrl() + 'CreateEmptyGroupEnrollmentWithCertificate',
    getOrphanDeviceCount: getDeviceManagementUrl() + 'GetOrphanDeviceCount',
    getOrphanDeviceList: getDeviceManagementUrl() + 'GetOrphanDeviceList',
    reuploadDecommisionedDevice: getDeviceManagementUrl() + 'ReuploadDecommisionedDevice',
    bulkUpdateOrphanDevice: getDeviceManagementUrl() + 'BulkUpdateOrphanDevice',
    bulkUpdateOrphanDeviceFromCSV: getDeviceManagementUrl() + 'BulkUpdateOrphanDeviceFromCSV',
    getTotalTelemetryCountForAllDevices: getDeviceManagementUrl() + 'GetTotalTelemetryCountForAllDevices',
    getTotalTelemetryCount: getDeviceManagementUrl() + 'GetTotalTelemetryCount',
    getSensorDetails: getDeviceManagementUrl() + 'GetSensorDetails',


    // Firmware
    getAllFirmware: getSecuredAPIUrl() + 'allFirmwares',
    downloadSpecificFirmware: getCoreAPIBaseUrl() + 'download/',
    deleteSpecificFirmware: getCoreAPIBaseUrl() + 'remove/',
    saveFirmware: getSecuredAPIUrl() + 'save',

    //get device location API
    getDeviceLocation: getDeviceLocationAPIUrl() + 'GetSonitorData',
    //ISCO
    //getIscoOverviewPageData: iscoOverviewPageData(),
    //getIscoLandingPageChartData: iscoLandingPageChartData(),
    getIscoOverviewPageData: getSupplyChainDataUrl() + 'landingpage_dashboard1',
    getMxDData: getSupplyChainDataUrl() + 'GetMxDData',

    // MPS Dashboard
    getSummaryCardData: getSupplyChainDataUrl() + 'GetSummaryCard',
    getResiliencyCardData: getSupplyChainDataUrl() + 'ResiliencyCard',
    getOTIFLandingCard: getSupplyChainDataUrl() + 'OTIFLandingCard',
    getLogisticsCard: getLogisticsCard(),

    //OTIF Dashboard
    getOverallOTIFCard: getSupplyChainDataUrl() + 'OverallOTIF',
    getOTIFSummaryCard: getSupplyChainDataUrl() + 'OTIFSummaryCard',
    getOTIFCharts: getSupplyChainDataUrl() + 'GetOTIFCharts',
    getOTIFPerCustomer: getSupplyChainDataUrl() + 'OTIFPerCustomer1',
    getFactorsEffOTIF: getSupplyChainDataUrl() + 'FactorsEffOTIF',
    getOtifMapData: getSupplyChainDataUrl() + 'GetOTIFMapData',
    getProdDowntimeData: getSupplyChainDataUrl() + 'OTIFPopUp1Table',

    //OEE Dashboard
    getOEESummaryCard: getSupplyChainDataUrl() + 'GetOEESummaryCard',
    getDAPQCharts: getSupplyChainDataUrl() + 'GetDAPQCharts',
    getOEETrendData: getSupplyChainDataUrl() + 'GetOEETrend',
    getOeeMapData: getSupplyChainDataUrl() + 'GetOEEMapData',

    getLandingOeeData: getSupplyChainDataUrl() + 'OEELandingCard',
    getLandingInventoryData: getSupplyChainDataUrl() + 'GetLogisticsCard',

    getDigitalTwinData: getSupplyChainDataUrl() + 'UpdateADT',

    getPBIToken: getSupplyChainDataUrl() + 'GetPBIToken',
    getDigitalTwinModelData: getSupplyChainDataUrl() + 'GetADTState',
    getDemandAttainmentData: getSupplyChainDataUrl() + 'GetOEEPopup1',
    getUtilDemandChartData: getSupplyChainDataUrl() + 'GetUtilDemandChart',

    //Supply Chain Cost Dashboard
    getSCCSummaryCard: getSupplyChainDataUrl() + 'SCCSummaryCard',
    getSCCCharts: getSupplyChainDataUrl() + 'GetSCCCharts',


    getPlannedDowntimePopup: getPlannedDowntimePopup(),
    getUnplannedDowntimePopup: getUnplannedDowntimePopup(),

    getSimulationChatbotData: getValutBackendUrl() + 'smiulationapi/process',
    getTmsChatbotData: getValutBackendUrl() + 'pepsicoapi/process',
    getSoeChatbotData: getValutBackendUrl() + 'soeapi/process',
    getChatbotData: getValutBackendUrl() + 'integratedapi/process',
    getMsgOptions: getValutBackendUrl() + 'integratedapi/questions',

    ValidateAccess: getSupplyChainDataUrl() + 'ValidateAccess',
    requestAccess: getSupplyChainDataUrl() + 'RequestAccessWorkflow',

    //MEIO - Common APIs between Executive Summary Page & Output Page
    getProductSegmentDropdown: getMEIOUrl() + 'productsegmenttype',
    getProductSubTypeDropdown: getMEIOUrl() + 'productsegmentsubtype',

    //MEIO - Executive Summary Page 
    getExecutiveSummaryMEIOCards: getMEIOUrl() + 'cards',
    getExecutiveSummaryProduct: getMEIOUrl() + 'productsegment',
    getExecutiveSummaryTopologyMap: getMEIOUrl() + 'locationinventorymap',
    getExecutiveSummaryStocking: getMEIOUrl() + 'stockingstrategy',

    // MEIO - Output Page 
    getOutputPageScenarios: getMEIOUrl() + 'scenarionames',
    getOutputPageCards: getMEIOUrl() + 'ouputcards',
    getMEIOOutputOptimizedBar: getMEIOUrl() + 'currentvsoptimisedss',
    getMEIOOutputPrdouctSegment: getMEIOUrl() + 'productsegmentgraph',
    getMEIOOutputStockingStrategy: getMEIOUrl() + 'outputstockingstrategy',
    getsafetystockdata: getMEIOUrl() + 'getsafetystockdata',

    //MEIO - Parameter Page
    getParameterTableData: getMEIOUrl() + 'parametertabdetails',
    getUpdateSupplynetwork: getMEIOUrl() + 'updatesupplynetwork',
    getUpdateInvPolicies: getMEIOUrl() + 'updateinventorypolicies',
    getSubmitScenario: getMEIOUrl() + 'submitupdates',
    getAllowparamateredits: getMEIOUrl() + 'allowparamateredits',

    //MEIO - Inventory Simulation Page
    getMEIOSimulationPageDropdown: getMEIOUrl() + 'skuplanttype',
    getMEIOInventorySimulation: getMEIOUrl() + 'inventorysimulation',

    //MEIO - Scenario Comparison Page
    getScenarioComparisonTable: getMEIOUrl() + 'allstockingstrategy',

    //Traceability
    getLots: getTraceabilityUrl() + 'GetLots',
    getOverallView: getTraceabilityUrl() + 'GetOverallView',
    getGraphDropDown1: getTraceabilityUrl() + 'GetGraphDropDown1',
    getGraph: getTraceabilityUrl() + 'GetGraph',

    //Output Template
    getDemandForecastData: getSupplyChainDataUrl() + 'GetDemandForecastData',
    //getDemandForecastData: getOutputTempUrl()

    //S&OE Orders at Risk
    getOrdersAtRiskFilters: getSOEApiUrl()+'GetFilters',
    getOrdersAtRiskAllData: getSOEApiUrl()+'Data',
}
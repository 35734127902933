<div id="chart" class="radial-chart">
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [plotOptions]="chartOptions.plotOptions!"
    [labels]="chartOptions.labels!"
    [fill]="chartOptions.fill!"
    [stroke]="chartOptions.stroke!"
    [colors]="chartOptions.colors!"
  ></apx-chart>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexStroke,
  ApexLegend,
  ApexGrid,
  ApexAnnotations,
  ApexTitleSubtitle
} from "ng-apexcharts";
import { debounceTime, shareReplay } from 'rxjs/operators';
import { AppService } from 'src/app/services/app/app.service';
import { BarChartNegativeData } from './bar-chart-negative-data';

export type BarNegativeChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  annotations: ApexAnnotations;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-bar-chart-negative-group',
  templateUrl: './bar-chart-negative-group.component.html',
  styleUrls: ['./bar-chart-negative-group.component.scss']
})
export class BarChartNegativeGroupComponent implements OnInit {



  public chartOptions!: Partial<BarNegativeChartOptions>;
  @Input() data!: BarChartNegativeData;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    let suffixsymbol = this.data.yaxisData?.suffixformattersymbol;
    let dataLableSymbol = this.data.dataLabelsymbol;
    this.chartOptions = {
      series: this.data.series,
      legend: {
        show: true, // Set legend visibility here
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '10px',
        fontFamily: 'EYInterstate-Regular',
        labels: {
          colors: '#A7A7DC',
          useSeriesColors: false
        }
      },
      chart: {
        type: "bar",
        //    width: "88%",
        toolbar: {
          show: false
        }
      },


      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          distributed: false,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false,
        offsetY: -30,
        formatter: function (val) {
          return `${val}${dataLableSymbol}`
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: this.data.labels,
        axisBorder: {
          show: true,
          color: '#414257'
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: '#AFAEBA',
            fontSize: '0.8em',
          },
      
        },
        title: {
          // text: this.data.yaxisData?.xtitle,
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#AFAEBA',
            fontSize: '0.8em',
          },
          formatter: function (val) {
            return `${val}${suffixsymbol}`
          },
        },
        title: {
          text: this.data.yaxisData?.ytitle,
          offsetX: 12,
          style: {
            color: '#AFAEBA',
            fontSize: '10px',
            fontFamily: 'EYInterstate-Regular'
          },
        },
        tickAmount: this.data.yaxisData?.tickAmount,
        min: this.data.yaxisData?.min,
        max: this.data.yaxisData?.max
      },
      fill: {
        opacity: 1,
        colors: ['#87d3f2', '#188ce5'],
      },
      tooltip: {
        y: {
          title: {
            formatter: function (val) {
              return "";
            }
          }
        },
        theme: 'dark'
      },
      grid: {
        strokeDashArray: 4,
        borderColor: '#414257',
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      // grid: {
      //   xaxis: {
      //     lines: {
      //       show: false
      //     }
      //   },
      //   yaxis: {
      //     lines: {
      //       show: false
      //     }
      //   }
      // },
      title: {
        text: this.data.Title,
        style: {
          color: "#A7A7DC",
        }
      },
      annotations: {
        yaxis: this.data.annotation
      }
    };

  }
}

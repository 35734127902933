import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CloseButtonSnackbarComponent } from './close-button-snackbar-component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ApiService } from 'src/app/services/api.service';
import { MatSort } from '@angular/material/sort';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-mat-table-reusable',
  templateUrl: './mat-table-reusable.component.html',
  styleUrls: ['./mat-table-reusable.component.scss']
})
export class MatTableReusableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() columns: string[] = [];
  @Input() data: any[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() headers: { [key: string]: string } = {};
  @Input() tabName: string;
  @Input() totalRecords: number;
  @Input() currentPageIndex: number;
  public show_parameter_table: boolean;

  dataSource: MatTableDataSource<any>;
  showSearch: boolean[] = [];
  columnEditState: boolean[] = [];
  editableColumnValues: { [key: string]: any } = {};
  filteredData: any[] = [];
  isHoldingCostEditable: boolean = false;
  supplyNetworkToastMessage: string = 'Please filter plant name from the <span class="highlight">Plant Type</span> column then edit button will be enabled in the "<span class="highlight">Holding Cost</span>" column.';
  inventoryPoliciesToastMessage: string = 'Please filter plant name from the <span class="highlight">Plant Type</span> column then edit button will be enabled in the "<span class="highlight">Min service requirement</span>", "<span class="highlight">Max service Requirement</span>", "<span class="highlight">Customer Service Time</span>" columns.';
  selectedIndexFirstToastMessage: string = 'Edit/Provide parameters under <span class="highlight">Supply Network</span> and <span class="highlight">Inventory Policies tab.</span>'
  private activeSearchIndex: number = -1;

  private snackbarRef: MatSnackBarRef<any>;
  @Input() tabs: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSize: number = 50;
  pageSizeOptions: number[] = [];
  invPoliciesPayload: any;
  filteredPlanType: any;
  filterValues: { [key: string]: string } = {};
  showScenarioInput: boolean = false;
  scenarioName: string;
  allowParamEdit: boolean = false;
  @Output() paginateChange = new EventEmitter<PageEvent>();
  @Output() filterChange = new EventEmitter<any>();
  isLoading: boolean = false;
  isIndividualUser: boolean = false;

  constructor(private _route: Router,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private userService: UserService) {
    this.isIndividualUser = this.userService.isIndividualUser();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.showSearch = this.columns.map(() => false);
    this.columnEditState = this.columns.map(() => false);
    this.editableColumnValues = {};
    if (this.show_parameter_table) {
      this.displayedColumns = [...this.columns, 'actions'];
    }

    this.show_parameter_table = this._route.url.includes('meio-parameters');
    if (this.tabName === 'Historical Demand') {
      this.displayToaster(this.selectedIndexFirstToastMessage);

    } else if (this.tabName === 'Supply Network') {
      this.showScenarioInput = true;
      this.getAllowParamEdit();
      this.displayToaster(this.supplyNetworkToastMessage);
    } else if (this.tabName === 'Inventory Policies') {
      this.showScenarioInput = true;
      this.getAllowParamEdit();
      this.displayToaster(this.inventoryPoliciesToastMessage);
    }
    if (this.totalRecords > 0 && this.paginator) {
      this.paginator.length = this.totalRecords;
    }
  }

  getAllowParamEdit(): void {
    this.apiService.allowparamateredits().subscribe({
      next: (response) => {
        if (response.allowEdits === true) {
          this.allowParamEdit = true;
        }
        else {
          this.allowParamEdit = false;
        }
      },
      error: (error) => {
        console.log('Error in getting the parameter edit access', error);
      }
    })
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getHeader(column: string): string {
    return this.headers[column] || column;
  }

  startEditingRow(element: any): void {
    element.editing = true;
  }

  cancelEditingRow(element: any): void {
    element.editing = false;
  }

  updateRow(element: any): void {
    element.editing = false;
    // Persist changes to data source
    this.dataSource.data = [...this.dataSource.data];
    this.dataSource.paginator = this.paginator;
    if (this.tabName === 'Supply Network') {
      const payload = {
        "plantType": element.plantType,
        "productName": element.pName,
        "siteName": element.sName,
        "holdingCost": element.holdingCost
      }
      this.apiService.updateSupplynetwork(payload).subscribe(
        (response) => {
          if (response && response.message === "Request accepted.") {
            this.displayToaster('Holding Cost updated successfully');
          } else {
            this.displayToaster('Error in updating the data to Supply Network');
          }
        },
        (error) => {
          this.displayToaster('Error in updating the data to Supply Network');
          console.log('Error in updating the data to Supply Network', error);
        })
    }
  }

  isRowEditable(element: any): boolean {
    return !!element.editing;
  }

  isRowFieldEditable(element: any, column: string): boolean {
    return element.editing && ['holdingCost', 'minServiceRequirement', 'maxServiceRequirement', 'customerServiceTime'].includes(column);
  }

  startEditingColumn(index: number): void {
    this.columnEditState[index] = true;
    const column = this.columns[index];
    this.filteredData = this.dataSource.filteredData;
    this.editableColumnValues[column] = this.filteredData[0][column]; // Use the first value as initial value
  }

  cancelEditingColumn(index: number): void {
    this.columnEditState[index] = false;
  }

  updateColumn(index: number): void {
    const column = this.columns[index];
    const newValue = this.editableColumnValues[column];

    this.filteredData.forEach(row => {
      row[column] = newValue;
      this.filteredPlanType = row.plantType;
    });

    this.dataSource.data = [...this.data];
    this.dataSource.paginator = this.paginator;
    this.columnEditState[index] = false;
    if (this.tabName === 'Supply Network' && column === 'holdingCost') {
      const payload = {
        "plantType": this.filteredPlanType,
        "productName": "",
        "siteName": "",
        "holdingCost": newValue
      }
      this.apiService.updateSupplynetwork(payload).subscribe(
        (response) => {
          if (response && response.message === "Request accepted.") {
            this.displayToaster('Holding Cost updated successfully');
          } else {
            this.displayToaster('Error in updating the data to Supply Network');
          }
        },
        (error) => {
          this.displayToaster('Error in updating the data to Supply Network');
          console.log('Error in updating the data to Supply Network', error);
        })
    }
    else if (this.tabName === 'Inventory Policies' && ['minServiceRequirement', 'maxServiceRequirement', 'customerServiceTime'].includes(column)) {
      if (column === 'minServiceRequirement') {
        this.invPoliciesPayload = {
          "plantType": this.filteredPlanType,
          "productName": "",
          "siteName": "",
          "minServiceRequirement": newValue,
          "maxServiceRequirement": "",
          "customerServiceTime": ""
        }
      } else if (column === 'maxServiceRequirement') {
        this.invPoliciesPayload = {
          "plantType": this.filteredPlanType,
          "productName": "",
          "siteName": "",
          "minServiceRequirement": "",
          "maxServiceRequirement": newValue,
          "customerServiceTime": ""
        }
      } else if (column === 'customerServiceTime') {
        this.invPoliciesPayload = {
          "plantType": this.filteredPlanType,
          "productName": "",
          "siteName": "",
          "minServiceRequirement": "",
          "maxServiceRequirement": "",
          "customerServiceTime": newValue
        }
      }
      this.apiService.updateInvPolicies(this.invPoliciesPayload).subscribe(
        (response) => {
          if (response && response.message === "Request accepted.") {
            this.displayToaster('Inventory Policies Updated Successfully');
          }
          else {
            this.displayToaster('Error in Updating the Data to Inventory Policies');
          }
        },
        (error) => {
          this.displayToaster('Error in Updating the Data to Inventory Policies');
          console.log('Error in updating the data to Inventory Policies', error);
        })
    }
  }

  isColumnEditable(index: number): boolean {
    return this.columnEditState[index];
  }

  onColumnValueChange(value: any, column: string): void {
    this.editableColumnValues[column] = value;
    this.filteredData.forEach(row => {
      row[column] = value;
    });
    this.dataSource.data = [...this.dataSource.data];
    this.dataSource.paginator = this.paginator;
  }

  onRowFieldValueChange(value: any, element: any, column: string): void {
    element[column] = value;
    this.dataSource.data = [...this.dataSource.data];
    this.dataSource.paginator = this.paginator;
  }

  displayToaster(message: string): void {
    this.snackbarRef = this.snackBar.openFromComponent(CloseButtonSnackbarComponent, {
      data: { message },
      duration: 7000, // Set duration to 0 for persistent display
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['toast-message']
    });

    this.snackbarRef.instance.closed.subscribe(() => {
      this.snackbarRef.dismiss(); // Dismiss the snackbar when closed
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading = true;
    if (changes.data && !changes.data.firstChange) {
      this.dataSource = new MatTableDataSource(this.data);
      this.isLoading = false;
      if (this.dataSource.data.length > 0) {
        this.displayedColumns = Object.keys(this.dataSource.data[0]);
      }
      if (this.paginator) {
        this.paginator.length = this.totalRecords;
        this.paginator.pageIndex = this.currentPageIndex;
      }
    }
  }

  toggleSearch(index: number): void {
    if (this.activeSearchIndex === index) {
      this.showSearch[index] = !this.showSearch[index];
    } else {
      this.showSearch = this.columns.map(() => false);
      this.showSearch[index] = true;
    }
    this.activeSearchIndex = this.showSearch[index] ? index : -1;
  }

  applyFilter(columnName: string, filterType): void {
    this.filterChange.emit({
      filterType: filterType,
      tabName: this.tabName,
      currentPageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filterValues: this.convertFilterValuesToCriterias()
    });

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    if (columnName === 'plantType') {
      this.isHoldingCostEditable = this.filterValues[columnName].length > 0 && this.isIndividualUser;
    }
  }

  clearFilter(columnName, filterType): void {
    this.filterValues[columnName] = '';
    this.filterChange.emit({
      filterType: filterType,
      tabName: this.tabName,
      currentPageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filterValues: { columnName: '', value: '' }
    });

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.isHoldingCostEditable = false;
  }

  private convertFilterValuesToCriterias(): any[] {
    const filterCriterias = [];
    for (const column in this.filterValues) {
      if (this.filterValues[column]) {
        filterCriterias.push({ columnName: column, value: this.filterValues[column] });
      }
    }
    return filterCriterias;
  }



  // applyFilter(filterValue: string, columnName: string): void {
  //   // Trim and convert the filter value to lowercase for consistency
  //   filterValue = filterValue.trim().toLowerCase();

  //   // Update the filter value for the specific column
  //   this.filterValues[columnName] = filterValue;

  //   // Define the filterPredicate inside applyFilter
  //   this.dataSource.filterPredicate = (data, filter) => {
  //     const searchTerms = JSON.parse(filter);
  //     let isMatch = true;

  //     for (const column in searchTerms) {
  //       if (searchTerms[column]) {
  //         // Check if the data for the column matches the filter
  //         const columnData = data[column];
  //         if (columnData !== null && columnData !== undefined) {
  //           isMatch = isMatch && columnData.toString().toLowerCase().includes(searchTerms[column]);
  //         } else {
  //           isMatch = false;
  //         }
  //       }
  //     }

  //     return isMatch;
  //   };

  //   // Trigger the filtering process by updating the data source's filter
  //   this.dataSource.filter = JSON.stringify(this.filterValues);

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }

  //   // Example: Set a property based on a specific column filter
  //   if (columnName === 'plantType') {
  //     this.isHoldingCostEditable = filterValue.length > 0;
  //   }
  // }

  isSiteEditable(column: string): boolean {
    return column === 'holdingCost' && this.isHoldingCostEditable && this.allowParamEdit;
  }

  isEditableHeader(column: string): boolean {
    if (this.tabName === 'Supply Network' && column === 'holdingCost' && this.allowParamEdit) {
      return true;
    }
    if (this.tabName === 'Inventory Policies' && ['minServiceRequirement', 'maxServiceRequirement', 'customerServiceTime'].includes(column) && this.allowParamEdit) {
      return true;
    }
    return false;
  }

  isSubmitDisabled(): boolean {
    return !this.scenarioName || this.scenarioName.trim().length === 0;
  }

  submit(): void {
    if (this.isIndividualUser) {
      const payload = {
        "scenarioName": this.scenarioName
      }
      this.apiService.submitScenario(payload).subscribe({
        next: (response) => {
          if (response && response.message === "Request accepted.") {
            this.displayToaster('Scenario Name Submitted Successfully');
            this.scenarioName = '';
          } else if (response && response.message === "Update in-progress") {
            this.displayToaster('The previous job is running. Please be patient until it completes..!!');
            this.scenarioName = '';
          }
          else {
            this.displayToaster('Error in Submitting the Scenario Name');
          }
        },
        error: error => {
          this.displayToaster('Error in Submitting the Scenario Name');
        }
      })
    } else {
      // Optionally handle the case where a non-individual tries to submit
      console.log('Submit action is not allowed for non-individual users.');
    }
  }

  onPaginateChange(event: PageEvent) {
    this.paginateChange.emit(event);
  }
}

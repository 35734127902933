export const CATEGORY_DATA = [
    {
        id: 1,
        code: 'MANUFACTURING',
        label: 'Industry 4.0',
        title: 'Supply Chain & Ops Platform',
        class: 'manufacturing',
        userRoles: [1, 2, 3]
    }
];

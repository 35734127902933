import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { CardComponent } from '../common/components/card/card.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { THIRD_PARTY_CONFIGURATIONS } from '../configurations/third-party-config';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { RolePipe } from './components/customPipe/role.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { DynamicIframeLoaderComponent } from '../common/components/dynamic-iframe-loader/dynamic-iframe-loader.component';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from './components/mat-icon/icon.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { BarChartComponent } from './components/ngx-charts/bar-chart/bar-chart.component';
import { GroupedBarChartComponent } from './components/ngx-charts/grouped-bar-chart/grouped-bar-chart.component';
import { BarChartHorizontalComponent } from './components/ngx-charts/bar-chart-horizontal/bar-chart-horizontal.component';
import { MapComponent } from './components/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ColumnChartComponent } from './components/highcharts/column-chart/column-chart.component';
import { BubbleChartComponent } from './components/highcharts/bubble-chart/bubble-chart.component';
import { PieChartComponent } from './components/highcharts/pie-chart/pie-chart.component';
import { MapChartComponent } from './components/highcharts/map-chart/map-chart.component';
import { ProdDowntimeComponent } from '../features/home/components/otif-dashboard/prod-downtime/prod-downtime.component';
import { NgxLineChartComponent } from './components/ngx-charts/ngx-line-chart/ngx-line-chart.component';
import { TreeGraphBoxLayoutComponent } from './components/highcharts/tree-graph-box-layout/tree-graph-box-layout.component';
import { SunburstComponent } from './components/highcharts/sunburst/sunburst.component';
import { powerBiComponent } from './components/powerBi/powerBi.component';
import { DialogModalComponent } from './components/dialog-modal/dialog-modal.component';
import { BarLineChartComponent } from './components/highcharts/bar-line-chart/bar-line-chart.component';
import { StackedBarComponent } from './components/highcharts/stacked-bar/stacked-bar.component';
import { SolidGaugeComponent } from './components/highcharts/solid-gauge/solid-gauge.component';
import { FunnelChartComponent } from './components/highcharts/funnel-chart/funnel-chart.component';
import { AreaChartComponent } from './components/highcharts/area-chart/area-chart.component';
import { MultipleKpiGaugeComponent } from './components/highcharts/multiple-kpi-gauge/multiple-kpi-gauge.component';
import { VariwideChartComponent } from './components/highcharts/variwide-chart/variwide-chart.component';
import { HighChartComponent } from './components/highcharts/high-chart/high-chart.component';
import { ApexBarComponent } from './components/apex-charts/apex-bar/apex-bar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CardsComponent } from './components/reusable-comp/cards/cards.component';
import { SelectDropdownComponent } from './components/reusable-comp/select-dropdown/select-dropdown.component';
import { PageHeaderComponent } from './components/reusable-comp/page-header/page-header.component';
import { DatePickerComponent } from './components/reusable-comp/date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadialSemiCircleGuageChartComponent } from './components/apex-charts/radial-semi-circle-guage-chart/radial-semi-circle-guage-chart.component';
import { ChartMixedComponent } from './components/apex-charts/chart-mixed/chart-mixed.component';
import { MapForecastChartComponent } from './components/apex-charts/map-forecast-chart/map-forecast-chart.component';
import { ApexLineComponent } from './components/apex-charts/apex-line/apex-line.component';
import { BarChartGroupComponent } from './components/apex-charts/bar-chart-group/bar-chart-group.component';
import { BarChartNegativeGroupComponent } from './components/apex-charts/bar-chart-negative-group/bar-chart-negative-group.component';
import { TableReusableComponent } from './components/table-reusable/table-reusable.component';
import { ForecastModelsComponent } from '../features/home/components/otif-dashboard/demand-forecast-asset/forecast-models/forecast-models.component';
import { DataTableSkuComponent } from '../features/home/components/otif-dashboard/demand-forecast-asset/data-table-sku/data-table-sku.component';
import { CustomNumberPipe } from './components/custom-pipes/custom-number.pipe';
import { MatTableReusableComponent } from './components/mat-table-reusable/mat-table-reusable.component';
import { ToggleButtonComponent } from './components/reusable-comp/toggle-button/toggle-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VariwideSplineChartComponent } from './components/highcharts/variwide-spline-chart/variwide-spline-chart.component';
import { UsCountiesMapComponent } from './components/highcharts/us-counties-map/us-counties-map.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CloseButtonSnackbarComponent } from './components/mat-table-reusable/close-button-snackbar-component';
import { ThreeDForceGraphComponent } from './components/three-d-force-graph/three-d-force-graph.component';
import { GraphNodeComponent } from './components/three-d-force-graph/graph-node/graph-node.component';
import { MatMenuModule } from '@angular/material/menu';

const materialModules = [
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatTableExporterModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    MatSortModule,
    MatSelectModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    DragDropModule,
    ReactiveFormsModule,
    MatMenuModule
];

const reusableComponents = [
    CardComponent,
    DynamicIframeLoaderComponent,
    SpinnerComponent,
    BarChartHorizontalComponent,
    CircleProgressComponent,
    BarChartComponent,
    GroupedBarChartComponent,
    MapComponent,
    ColumnChartComponent,
    BubbleChartComponent,
    PieChartComponent,
    MapChartComponent,
    MapForecastChartComponent,
    ProdDowntimeComponent,
    MapComponent,
    SunburstComponent,
    NgxLineChartComponent,
    powerBiComponent,
    DialogModalComponent,
    TreeGraphBoxLayoutComponent,
    BarLineChartComponent,
    AreaChartComponent,
    StackedBarComponent,
    SolidGaugeComponent,
    FunnelChartComponent,
    MultipleKpiGaugeComponent,
    VariwideChartComponent,
    HighChartComponent,
    ApexBarComponent,
    ApexLineComponent,
    BarChartGroupComponent,
    BarChartNegativeGroupComponent,
    RadialSemiCircleGuageChartComponent,
    ChartMixedComponent,
    TableReusableComponent,
    ForecastModelsComponent,
    DataTableSkuComponent,
    CardsComponent,
    SelectDropdownComponent,
    PageHeaderComponent,
    DatePickerComponent,
    MatTableReusableComponent,
    CloseButtonSnackbarComponent,
    ToggleButtonComponent,
    VariwideSplineChartComponent,
    UsCountiesMapComponent,
    ThreeDForceGraphComponent,
    GraphNodeComponent
];

@NgModule({
    declarations: [
        ...reusableComponents,
        RolePipe,
        SafePipe,
        DialogBoxComponent,
        DialogModalComponent,
        TreeGraphBoxLayoutComponent,
        CardsComponent,
        SelectDropdownComponent,
        PageHeaderComponent,
        DatePickerComponent,
        ApexLineComponent,
        CustomNumberPipe,
        MatTableReusableComponent,
        CloseButtonSnackbarComponent,
        ToggleButtonComponent
    ],
    imports: [
        CommonModule,
        ...materialModules,
        ToastrModule.forRoot(THIRD_PARTY_CONFIGURATIONS.TOASTR_CONFIG),
        NgCircleProgressModule.forRoot(THIRD_PARTY_CONFIGURATIONS.NG_CIRCLE_PROGRESS_CONFIG),
        ModalModule.forRoot(),
        NgScrollbarModule,
        IconModule,
        HighchartsChartModule,
        NgApexchartsModule,
        NgxChartsModule,
        LeafletModule,
        FormsModule
    ],
    exports: [
        ...materialModules,
        ...reusableComponents,
        NgCircleProgressModule,
        ToastrModule,
        ModalModule,
        RolePipe,
        NgScrollbarModule,
        IconModule,
        HighchartsChartModule,
        NgApexchartsModule,
        NgxChartsModule,
        LeafletModule,
    ],
    providers: [
        ToastrService,
        BsModalService,
        DatePipe,
        RolePipe,
        SafePipe
    ]
})
export class SharedModule { }